import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { usePerformMutation } from "../../../Api/PerformMutation";
import { useMultisig } from "../../../Context/MultisigContext";
import { createMultisigApi, updateMultisigApi } from "../../../Api/ApiList";

const AddMultisigModal = ({ isEditMode, refetchMultisig, setEditMode }) => {
  const { editModal, editMultisigData, toggleEdit, data } = useMultisig();
  const [availableSignatories, setAvailableSignatories] = useState(data?.wallets || []);
  const [selectedSignatories, setSelectedSignatories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const { mutate } = usePerformMutation(
    () => {
      reset();
      toggleEdit();
      setEditMode(false);
      setTimeout(() => {
        refetchMultisig();
      }, 500);
    },
    () => {
      setLoading(false);
    }
  );

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: null,
      threshold: null,
      ms_name: null,
      ms_address: null,
      members: [],
    },
  });

  const handleMultisig = async (data) => {
    setLoading(true);
    await mutate({ url: isEditMode ? updateMultisigApi : createMultisigApi, data, isUpdate: isEditMode || false });
    setLoading(false);
  };

  useEffect(() => {
    if (isEditMode && editMultisigData) {
      try {
        // const members = JSON.parse(editMultisigData?.members || "[]");
        const members = Array.isArray(editMultisigData?.members) ? editMultisigData?.members : JSON.parse(editMultisigData?.members || "[]");
        const selectedMembers = Array.isArray(members)
          ? members.map((member) => {
              const signatory = availableSignatories.find((item) => item.address === member?.address);
              return signatory || { address: member?.address, name: "Unknown" };
            })
          : [];

        setSelectedSignatories(selectedMembers);
        setAvailableSignatories(data.wallets.filter((signatory) => !members.some((member) => member.address === signatory.address)));

        // setAvailableSignatories(data.wallets.filter((signatory) => members.address!==signatory.address));
        setValue("threshold", editMultisigData.threshold);
        setValue("id", editMultisigData.id);
        setValue("ms_name", editMultisigData.ms_name);
        setValue("ms_address", editMultisigData.ms_address);
        setValue("members", members);
      } catch (error) {
        console.error("Failed to parse members:", error);
      }
    } else {
      setSelectedSignatories([]);
      setAvailableSignatories(data.wallets);
      reset();
    }
  }, [isEditMode, editMultisigData, setValue, reset, data.wallets]);

  const filteredSignatories = availableSignatories.filter((signatory) => signatory?.name?.toLowerCase().includes(searchQuery?.toLowerCase()) || signatory.address.includes(searchQuery));

  const handleSelectSignatory = (signatory) => {
    setAvailableSignatories((prev) => prev.filter((item) => item !== signatory));
    setSelectedSignatories((prev) => {
      const updated = [...prev, signatory];
      const addresses = updated.map((item) => item); // Extract only the addresses
      setValue("members", addresses, { shouldValidate: true }); // Update form state with addresses
      return updated;
    });
  };

  const handleDeselectSignatory = (signatory) => {
    setSelectedSignatories((prev) => {
      const updated = prev.filter((item) => item !== signatory);
      const addresses = updated.map((item) => item); // Extract only the addresses
      setValue("members", addresses, { shouldValidate: true }); // Update form state with addresses
      return updated;
    });
    setAvailableSignatories((prev) => [...prev, signatory]);
  };

  useEffect(() => {
    register("members", {
      validate: (value) => value.length > 0 || "At least one signatory must be selected",
    });
  }, [register]);

  const closeModal = () => {
    toggleEdit();
    setEditMode(false);
  };

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={closeModal} className="transaction-modal budget-modal">
      <ModalHeader toggle={closeModal}>{isEditMode ? "Edit Multisig" : "Add Multisig"}</ModalHeader>
      <Form onSubmit={handleSubmit(handleMultisig)} noValidate autoComplete="off">
        <ModalBody>
          <Row className="g-3">
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">* Address:</label>
                <input {...register("ms_address", { required: "Address is required" })} className={`form-control ${errors.ms_address ? "is-invalid" : ""}`} type="text" placeholder="Enter avail address" />
                {/* <p className="helper-text">The name is for unique identification of the account in your owner lists.</p> */}
                {errors.ms_address && <div className="invalid-feedback">{errors.ms_address.message}</div>}
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">* Name:</label>
                <input {...register("ms_name", { required: "Multisig Name is required" })} className={`form-control ${errors.ms_name ? "is-invalid" : ""}`} type="text" placeholder="Enter multisig name" />
                <p className="helper-text">The name is for unique identification of the account in your owner lists.</p>
                {errors.ms_name && <div className="invalid-feedback">{errors.ms_name.message}</div>}
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">* Threshold:</label>
                {/* <input {...register("threshold", { required: "Threshold is required" })} className={`form-control ${errors.threshold ? "is-invalid" : ""}`} type="text" placeholder="Enter threshold" /> */}
                <input
                  {...register("threshold", {
                    required: "Threshold is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Threshold must be a number",
                    },
                  })}
                  className={`form-control ${errors.threshold ? "is-invalid" : ""}`}
                  type="text"
                  placeholder="Enter threshold"
                />
                <p className="helper-text">The threshold for approval should be less or equal to the number of signatories for this multisig.</p>
                {errors.threshold && <div className="invalid-feedback">{errors.threshold.message}</div>}
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <input onChange={(e) => setSearchQuery(e.target.value)} className="form-control" type="text" placeholder="Filter by name or address" />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Available Signatories:</label>
                <div className="form-control custom-textarea custom-scrollbar">
                  {filteredSignatories.length
                    ? filteredSignatories.map((item, index) => (
                        <div key={index} className="signatory-item" onClick={() => handleSelectSignatory(item)}>
                          {`${item?.name?.toUpperCase()} `}
                        </div>
                      ))
                    : "No Signatories Available"}
                </div>
                <p className="helper-text">
                  The signatories has the ability to create transactions using the multisig and approve transactions sent by others.Once the threshold is reached with approvals, the multisig transaction is enacted on-chain. <br /> Click on a signatory to select it.
                </p>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">* Selected Signatories:</label>
                <div className={`${errors.members ? "is-invalid" : ""} form-control custom-textarea custom-scrollbar`}>
                  {selectedSignatories.map((item, index) => (
                    <div key={index} className="signatory-item" onClick={() => handleDeselectSignatory(item)}>
                      {`${item?.name?.toUpperCase()}  `}
                    </div>
                  ))}
                </div>
                <p className="helper-text">Click on a selected signatory to remove it. It will move back to the available list.</p>
                {errors.members && <div className="invalid-feedback d-block">{errors.members.message}</div>}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggleEdit}>
            Cancel
          </Button>
          <Button type="submit" color="" disabled={loading}>
            {loading ? "Saving..." : isEditMode ? "Update" : "Add"} {loading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddMultisigModal;
