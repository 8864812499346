import { web3FromSource } from "@polkadot/extension-dapp";
import { hexToU8a } from "@polkadot/util";
import { BN } from "bn.js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledDropdown } from "reactstrap";
import { Href } from "../../../Constants";
import { useMultisig } from "../../../Context/MultisigContext";
import { useWallet } from "../../../Context/WalletContext";
import InnerLoader from "../../../Layout/InnerLoader";
import Loader from "../../../Layout/Loader";

const PendingTransactionModal = ({ isOpen, toggle, senderDetails, multisigTransactions, setPendingMultisigTransactions }) => {
  const [loading, setLoading] = useState(false);
  const [encodedCallData, setEncodedCallData] = useState(""); // New state for encoded call data
  const { transactionUpdates, setTransactionUpdates } = useMultisig();
  const { api } = useWallet();
  const [transactionsLoading, setTransactionLoading] = useState(false);
  const multisigMembers = typeof senderDetails?.members === "string" ? JSON.parse(senderDetails?.members) : senderDetails?.members;
  const [approvalsState, setApprovalsState] = useState({
    pendingApprovals: 0,
    approvals: [],
  });
  const hashApprovalType = [
    {
      text: "Approve this call hash",
      value: 1,
    },
    {
      text: "Cancel this call hash",
      value: 0,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      hash: multisigTransactions[0], // Leave it empty initially
      approvalType: "",
      amount: "",
      currentAddress: "",
      callData: "",
    },
  });
  const currentHash = watch("hash");

  const closeModal = () => {
    toggle("hashes");
  };

  // Get all pending multisig transaction hashes
  const getPendingMultisigTransactions = async (address) => {
    try {
      setTransactionLoading(true);
      const entries = await api.query.multisig.multisigs.entries(address);

      if (entries.length === 0) {
        setTransactionLoading(false);
        return [];
      }

      setTransactionLoading(false);
      // Loop through the pending transactions
      return entries.map(([key]) => key.args[1].toString());
    } catch (error) {
      setTransactionLoading(false);
      console.error("Error fetching pending multisig transactions:", error);
      return []; // Return an empty array in case of error
    }
  };
  // ====================================================================== //
  // Track approval by hashes
  const trackApprovals = async () => {
    const multisigInfo = await api.query.multisig.multisigs(senderDetails?.ms_address, currentHash);

    try {
      if (multisigInfo.isSome) {
        const { approvals, when } = multisigInfo.unwrap();
        const pendingApprovals = senderDetails?.threshold - approvals.length;
        console.log(`Multisig Transaction Details:`);
        console.log(`- Block created: ${when.toHuman()}`);
        console.log(`- Total Approvals Required (Threshold): ${senderDetails?.threshold}`);
        console.log(`- Current Approvals (${approvals.length}):`);
        setApprovalsState({
          approvals: approvals.map((address) => address.toHuman()),
          pendingApprovals,
        });
      }
    } catch (err) {
      toast.error(`Error occurred -${err.message}`);
    }
  };

  // ====================================================================== //
  // Get maybe time-point (height and index for next approval )
  const getMaybeTimepoint = async (multisigAddress, callHash) => {
    // Initialize the API

    // Query the multisig storage for the multisig address
    const multisigData = await api.query.multisig.multisigs(multisigAddress, callHash);

    // Check if multisig data exists
    if (multisigData.isSome) {
      const { when } = multisigData.unwrap();
      const timepoint = {
        height: when.height.toString(),
        index: when.index.toString(),
      };

      return timepoint;
    } else {
      console.log("No multisig data found (likely the first approval).");
      return null; // Return null for the first approval
    }
  };

  // ====================================================================== //
  // Next multisig approval
  const signNextApproval = async (data) => {
    if (!api) {
      toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
      return;
    }
    try {
      setLoading(true);
      const heightAndIndex = await getMaybeTimepoint(senderDetails?.ms_address, data?.hash);
      const injector = await web3FromSource("subwallet-js");
      // if (!api) await injector.metadata.provide(getInjectorMetadata(api));

      const fromAddress = data.currentAddress;

      const otherSignatories = senderDetails?.members
        ? JSON.parse(senderDetails.members)
            .filter((member) => member.address !== fromAddress) // Exclude the sender's address
            .map((member) => member.address)
            .sort() // Must be sorted
        : [];

      const transfer = api.tx.multisig.approveAsMulti(Number(senderDetails?.threshold), otherSignatories, heightAndIndex, data?.hash, {
        refTime: 196085000,
        proofSize: 3593,
      });

      await transfer.signAndSend(fromAddress, { signer: injector.signer }, ({ status, events }) => {
        if (status.isInBlock) {
          toast.success(`Transaction successful! Hash: ${status.asInBlock.toHex()}`);
        } else if (status.isFinalized) {
          toast.success("Transaction finalized.");
        } else {
          toast.success(`Transaction status: ${status.type}`);
        }

        events.forEach(({ phase, event: { data, method, section } }) => {
          setTransactionUpdates(`Next approval done for ${data.hash}`);
          console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
        });
      });

      reset({
        from_address: senderDetails?.ms_address || "",
        to_address: "",
        amount: "",
      });
      setTransactionUpdates(`Next approval done for ${data.hash}`);
      closeModal();
      // setEncodedCallData("");
      setLoading(false);
    } catch (err) {
      toast.error(`Transaction failed: ${err.message}`);
      console.error(err);
      setTransactionUpdates(`Error occurred for ${data.hash}`);
      setLoading(false);
    }
  };
  // ====================================================================== //
  // Decoded call data for final approval
  const decodeCallData = async (callData) => {
    if (!api || !callData) return;
    // setIsDecoding(true);

    try {
      // Clean the input hex string
      const cleanHex = callData.startsWith("0x") ? callData : `0x${callData}`;

      // Convert hex to Uint8Array
      const callDataU8a = hexToU8a(cleanHex);

      // Try to decode as Call directly
      const decodedCall = api.createType("Call", callDataU8a);

      // Get the call index
      const callIndex = decodedCall.callIndex;

      // Find the call in metadata
      const { method, section } = api.registry.findMetaCall(callIndex);

      // Get the arguments
      const args = decodedCall.args.map((arg) => arg.toHuman());

      // Set the decoded information
      return {
        section,
        method,
        args,
        callIndex: callIndex.toString(),
        raw: {
          hex: cleanHex,
          bytes: Array.from(callDataU8a),
        },
      };
    } catch (error) {
      // Try alternative decoding method if first attempt fails
      toast.error(`Some error occurred- ${error}`);
    }
  };
  // ====================================================================== //
  // Final approval for multisig transaction
  const signFinalApproval = async (data) => {
    if (!api) {
      toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
      return;
    }

    try {
      setLoading(true);
      const heightAndIndex = await getMaybeTimepoint(senderDetails?.ms_address, data?.hash);
      const maybeTimepoint = heightAndIndex ? api.createType("Option<PalletMultisigTimepoint>", heightAndIndex) : null;

      const injector = await web3FromSource("subwallet-js");
      const fromAddress = data.currentAddress;

      const otherSignatories = senderDetails?.members
        ? JSON.parse(senderDetails.members)
            .filter((member) => member.address !== fromAddress) // Exclude the sender's address
            .map((member) => member.address)
            .sort() // Must be sorted
        : [];

      const decodedCallData = await decodeCallData(data?.callData);
      if (!decodedCallData) {
        throw new Error("Failed to decode call data. Please ensure the data is correctly formatted.");
      }

      const callData = decodedCallData?.section == "proxy" ? api.tx[decodedCallData.section][decodedCallData.method](...decodedCallData.args) : api.tx[decodedCallData.section][decodedCallData.method](decodedCallData.args[0]?.Id, decodedCallData.args[1]?.split(",")?.join(""));
      const encodedCallData = callData;

      const maxWeight = {
        refTime: new BN(196085000),
        proofSize: new BN(3593),
      };

      const maxWeightForProxy = {
        refTime: new BN(169832736),
        proofSize: new BN(4706),
      };
      const transfer = api.tx.multisig.asMulti(Number(senderDetails?.threshold), otherSignatories, maybeTimepoint, encodedCallData, decodedCallData?.section == "proxy" ? maxWeightForProxy : maxWeight);

      await transfer.signAndSend(fromAddress, { signer: injector.signer }, ({ status, events }) => {
        if (status.isInBlock) {
          toast.success(`Transaction successful! Hash: ${status.asInBlock.toHex()}`);
        } else if (status.isFinalized) {
          toast.success("Transaction finalized.");
        } else {
          toast.success(`Transaction status: ${status.type}`);
        }

        events.forEach(({ phase, event: { data, method, section } }) => {
          console.log("🚀 ~ events.forEach ~ data:", data);
          setTransactionUpdates(`Final approval done for ${data.hash}`);
          if (section == "proxy" && method == "PureCreated") {
            toast.success((t) => (
              <span>
                Please keep this proxied address secure somewhere. You won’t be able to retrieve it again once the toast is dismissed := <b>{data?.[0]}</b>
                <Button close onClick={() => toast.dismiss(t.id)} />
              </span>
            ));
          }
          console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
        });
      });

      reset({
        from_address: senderDetails?.ms_address || "",
        to_address: "",
        amount: "",
      });
      closeModal();
      // setEncodedCallData("");
      setTransactionUpdates(`Final approval done for ${data.hash}`);
      setLoading(false);
    } catch (err) {
      toast.error(`Transaction failed: ${err.message}`);
      console.error(err);
      setTransactionUpdates(`Error occurred for ${data.hash}`);
      setLoading(false);
    }
  };

  // To Cancel selected multisig hash
  const rejectMultisigApproval = async (data) => {
    if (!api) {
      toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
      return;
    }
    try {
      setLoading(true);
      const fromAddress = approvalsState?.approvals[0];
      const threshold = senderDetails?.threshold; // Replace with the actual threshold value
      const otherSignatories = senderDetails?.members
        ? JSON.parse(senderDetails.members)
            .filter((member) => member.address !== fromAddress) // Exclude the sender's address
            .map((member) => member.address)
            .sort() // Must be sorted
        : [];

      const timepoint = await getMaybeTimepoint(senderDetails?.ms_address, data.hash);

      // Ensure the injector is ready for signing
      const injector = await web3FromSource("subwallet-js");

      // Create the `cancelAsMulti` transaction
      const cancelTransaction = await api.tx.multisig.cancelAsMulti(
        threshold,
        otherSignatories.sort(), // Ensure the signatories are sorted
        timepoint,
        data.hash
      );

      // Sign and send the transaction
      await cancelTransaction.signAndSend(fromAddress, { signer: injector.signer }, ({ status, events }) => {
        if (status.isInBlock) {
          toast.success(`Transaction included in block: ${status.asInBlock.toHex()}`);
        } else if (status.isFinalized) {
          toast.success(`Transaction finalized`);
        } else {
          toast.success(`Transaction status: ${status.type}`);
        }

        // Log events
        events.forEach(({ phase, event: { data, method, section } }) => {
          setTransactionUpdates(`Transaction rejected for ${data.hash}`);
          console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
        });
      });

      reset({
        from_address: senderDetails?.ms_address || "",
        to_address: "",
        amount: "",
      });
      closeModal();
      // setEncodedCallData("");
      toast.success("Multisig approval rejected successfully.");
      setTransactionUpdates(`Transaction rejected for ${data.hash}`);
      setLoading(true);
    } catch (err) {
      toast.error("Failed to reject multisig approval:", err.message);
      setTransactionUpdates(`Error occurred for ${data.hash}`);
      setLoading(false);
    }
  };

  // To get the pending multisig transaction hashes
  useEffect(() => {
    if (api && senderDetails) {
      (async function fetchTransactions() {
        const pendingMultisigTransactions = await getPendingMultisigTransactions(senderDetails?.ms_address);
        setPendingMultisigTransactions(pendingMultisigTransactions);
      })();
      // fetchTransactions();
    }
  }, [api, senderDetails, transactionUpdates]);

  // To track the approval status of current multisig transaction hash
  useEffect(() => {
    if (senderDetails?.ms_address && currentHash) {
      trackApprovals();
      // getMultisigCreator();
    }
  }, [senderDetails, currentHash, transactionUpdates]);

  useEffect(() => {
    if (senderDetails?.ms_address) {
      if (approvalsState.approvals[0]) {
        setValue("currentAddress", approvalsState.approvals[0]);
      }
      if (multisigTransactions[0]) {
        setValue("hash", multisigTransactions[0]);
      }
      setValue("approvalType", hashApprovalType[0]?.value || 1); // Fallback to default
    }
  }, [senderDetails, currentHash, transactionUpdates, toggle]);

  if (!api) return <Loader />;
  return (
    <Modal size="lg" centered isOpen={isOpen} toggle={() => closeModal()} className="transaction-modal budget-modal">
      <ModalHeader toggle={closeModal}>{"Pending Call Hashes"}</ModalHeader>
      {transactionsLoading ? (
        <InnerLoader />
      ) : multisigTransactions?.length ? (
        <form noValidate autoComplete="off" onSubmit={handleSubmit(watch("approvalType") == 0 ? rejectMultisigApproval : approvalsState?.pendingApprovals == 1 ? signFinalApproval : signNextApproval)}>
          <ModalBody>
            <Row className="g-3">
              <Col sm="12">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Pending Hashes:</label>
                  <select {...register("hash", { required: "Address is required" })} className={`form-control ${errors.hash ? "is-invalid" : ""}`}>
                    <option value="" disabled>
                      Select hash
                    </option>
                    {multisigTransactions?.map((member, index) => (
                      <option key={index} value={member}>
                        {member}
                      </option>
                    ))}
                  </select>
                  <p className="helper-text">The call hash from the list of available and unapproved calls.</p>
                  {errors.hash && <div className="invalid-feedback">{errors.hash.message}</div>}
                </FormGroup>
              </Col>
              {currentHash ? (
                <Col sm="12" className="d-flex ">
                  <UncontrolledDropdown className="common-dropdown">
                    <DropdownToggle caret color="primary">
                      {`Existing approvals ${approvalsState?.approvals?.length + "/" + senderDetails?.threshold}`}
                    </DropdownToggle>
                    <DropdownMenu>
                      {approvalsState?.approvals?.map((address, i) => (
                        <DropdownItem href={Href}>{address}</DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <div>
                    <p className="helper-text">The current approvals applied to this multisig</p>
                  </div>
                </Col>
              ) : null}
              <Col sm="12">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Approval Type:</label>
                  <select {...register("approvalType", { required: "Address is required" })} className={`form-control ${errors.approvalType ? "is-invalid" : ""}`}>
                    {hashApprovalType?.map((type, index) => (
                      <option key={index} value={type.value}>
                        {type.text}
                      </option>
                    ))}
                  </select>
                  <p className="helper-text">The operation type to apply. For approvals both non-final and final approvals are supported.</p>
                  {errors.approvalType && <div className="invalid-feedback">{errors.approvalType.message}</div>}
                </FormGroup>
              </Col>
              <Col sm="12">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Signatory:</label>
                  <select {...register("currentAddress", { required: "Signatory must be selected" })} className={`form-control ${errors.currentAddress ? "is-invalid" : ""}`}>
                    <option value="" disabled>
                      Select address
                    </option>
                    {multisigMembers
                      ?.filter((member) => !approvalsState?.approvals?.includes(member?.address))
                      ?.map((member, index) => (
                        <option key={index} value={member?.address}>
                          {member?.address}
                        </option>
                      ))}
                  </select>
                  <p className="helper-text">For approvals outstanding approvers will be shown, for hashes that should be cancelled the first approver is required.</p>
                  {errors.currentAddress && <div className="invalid-feedback">{errors.currentAddress.message}</div>}
                </FormGroup>
              </Col>

              {approvalsState?.pendingApprovals == 1 && watch("approvalType") != 0 ? (
                <Col sm="12">
                  <FormGroup className="form-group">
                    <label className="mb-2 form-label">Call data for final approval:</label>
                    <input
                      {...register("callData", {
                        required: "Call data is required",
                      })}
                      className={`form-control ${errors.callData ? "is-invalid" : ""}`}
                      type="text"
                      placeholder="Enter call data"
                    />
                    <p className="helper-text">The call data for this transaction matching the hash. Once sent, the multisig will be executed against this.</p>

                    {errors.callData && <div className="invalid-feedback">{errors.callData.message}</div>}
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                closeModal();
                // setEncodedCallData("");
                reset({
                  from_address: senderDetails?.ms_address || "",
                  to_address: "",
                  amount: "",
                });
              }}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? "Submitting..." : watch("approvalType") == "0" ? "Reject" : "Submit"}
            </Button>
          </ModalFooter>
        </form>
      ) : (
        <h4 className="text-center no-found">No Pending Transactions Found</h4>
      )}
    </Modal>
  );
};

export default PendingTransactionModal;
