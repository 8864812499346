import React from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";

const RecentCredit = ({ staticsData }) => {
  return (
    <Col md="12">
      <Card>
        <CardHeader>
          <h4>Recent Credit Transfers</h4>
        </CardHeader>
        <CardBody>
          <div className="table-responsive custom-scroll">
            <Table className="data-transfer-table">
              <thead className="border-bottom">
                <tr>
                  <th>Payment Description</th>
                  <th>Payment Source</th>
                  <th>Amount (in USD)</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {staticsData?.creditCardPaymentBudget?.length > 0 ? (
                  staticsData?.creditCardPaymentBudget?.map((item) => (
                    <tr key={item.id}>
                      <td>{item.payment_description}</td>
                      <td>{item.payment_source}</td>
                      <td>{item.amountUSD}</td>
                      <td>{item.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="">
                    <td colSpan="10" className="text-center no-found">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export default RecentCredit;
