import { Col, Row } from "reactstrap";
import { Href } from "../../Constants";

const FileUploadBrowser = ({ values, setFieldValue, ...props }) => {
  // Handle the drop event

  const onSelect = (event) => {
    const files = event.currentTarget.files;
    if (!files || files.length === 0) return;

    if (files.length > 1) {
      return;
    }

    setFieldValue(props.name, files[0]);
  };

  const handleClick = (event) => {
    event.currentTarget.value = "";
  };

  return (
    <>
      {/* Droppable area */}
      <div>
        {!values[props.name] && (
          <a href={Href} className="browse-file">
            Browse files
            <input {...props} onChange={onSelect} onClick={handleClick} />
          </a>
        )}
      </div>

      {/* Display selected file */}
      <div className={`overflow-section custom-scroll ${!values[props.name] ? "d-none" : ""}`}>
        <Row className="image-selection-list g-sm-4 g-3 ">
          <Col container>
            {values[props.name] && (
              <div className="import-list common-flex">
                <h6 className="pt-1 ">{values[props.name].name}</h6>
                <h6>{`(${values[props.name].type})`}</h6>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FileUploadBrowser;
