import React from "react";
import AvailBVIModal from "./AvailBVIForm";
import AvailCaymanModal from "./AvailCaymanForm";
import AvailDIFCModal from "./AvailDIFCForm";
import AvailWalletModal from "./AvailWalletForm";
import BankPaymentModal from "./BankPaymentsForm";
import CreditCardPaymentsModal from "./CreditCardPaymentsForm";
import EthereumWalletPaymentsModal from "./EthereumWalletPaymentsForm";

const BudgetFormsModal = ({ isEditMode, toggleEdit, refetch, type }) => {
  const allForms = {
    bank_payments: <BankPaymentModal isEditMode={isEditMode} />,
    credit_card_payments: <CreditCardPaymentsModal isEditMode={isEditMode} toggleEdit={toggleEdit} refetchBudget={refetch} />,
    ethereum_wallet: <EthereumWalletPaymentsModal isEditMode={isEditMode} toggleEdit={toggleEdit} refetchBudget={refetch} />,
    avail_wallet: <AvailWalletModal isEditMode={isEditMode} toggleEdit={toggleEdit} refetchBudget={refetch} />,
    avail_difc: <AvailDIFCModal isEditMode={isEditMode} toggleEdit={toggleEdit} refetchBudget={refetch} />,
    avail_cayman: <AvailCaymanModal isEditMode={isEditMode} toggleEdit={toggleEdit} refetchBudget={refetch} />,
    avail_bvi: <AvailBVIModal isEditMode={isEditMode} toggleEdit={toggleEdit} refetchBudget={refetch} />,
  };
  return type ? allForms[type] : null;
};

export default BudgetFormsModal;
