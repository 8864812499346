import { web3FromSource } from "@polkadot/extension-dapp";
import { isNumber } from "@polkadot/util";
import { signedExtensions } from "avail-js-sdk";
import { BN } from "bn.js";
import toast from "react-hot-toast";
import { blake2AsHex } from "@polkadot/util-crypto";
import { hexToU8a } from "@polkadot/util";

export const getInjectorMetadata = (api) => {
  return {
    chain: api.runtimeChain.toString(),
    specVersion: api.runtimeVersion.specVersion.toNumber(),
    tokenDecimals: api.registry.chainDecimals[0] || 18,
    tokenSymbol: api.registry.chainTokens[0] || "AVAIL",
    genesisHash: api.genesisHash.toHex(),
    ss58Format: isNumber(api.registry.chainSS58) ? api.registry.chainSS58 : 0,
    chainType: "substrate",
    icon: "substrate",

    /** !! IMPORTANT !!
     * This is the important part, we tell the extension how to handle our signedExtension (even if it seems it's already there)
     **/
    userExtensions: signedExtensions,
  };
};

export const generateEncodedCallData = async (api, moduleName, methodName, args) => {
  const fromAddress = args[0]?.Id || args[0]; // Extract address

  // Ensure the amount is a BN instance
  const amount = args[1] instanceof BN ? args[1] : new BN(String(args[1]).replace(/,/g, "")); // Handle string with commas

  const tx = api.tx[moduleName][methodName](fromAddress, amount);
  const encodedCallData = tx.method.toHex(); // Encoded transaction data
  return encodedCallData;
};

export const modifyAvailBalance = (balance) => {
  return Number(balance?.substr(0, balance.length - 18))?.toFixed(2);
};

export const getAvailBalance = async (api, address) => {
  try {
    // Fetch the account balance for the specified address
    const { data: freeBalance } = await api.query.system.account(address);

    // Convert the free balance to a string and parse it as a number
    const availableBalance = freeBalance?.free?.toString();

    // Format the balance by dividing it by 10^8 and ensuring 2 decimal places

    return modifyAvailBalance(availableBalance);
  } catch (error) {
    console.error("Error fetching Avail balance:", error);
    return null;
  }
};

export const signTransaction = async (api, data, senderDetails, setTransactionUpdates, hash, encodedCallData, otherSuccess, otherError) => {
  if (!api) {
    toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
    return;
  }
  try {
    const injector = await web3FromSource("subwallet-js");
    if (!api) await injector.metadata.provide(getInjectorMetadata(api));
    const fromAddress = data?.proxiedName;
    const callHash = hash || (await blake2AsHex(hexToU8a(encodedCallData)));

    const otherSignatories = senderDetails?.members
      ? JSON.parse(senderDetails.members)
          .filter((member) => member.address !== fromAddress) // Exclude the sender's address
          .map((member) => member.address)
          .sort() // Must be sorted
      : [];

    const transfer = api.tx.multisig.approveAsMulti(Number(senderDetails?.threshold), otherSignatories, null, callHash, {
      refTime: 196085000,
      proofSize: 3593,
    });

    await transfer.signAndSend(fromAddress, { signer: injector.signer }, ({ status, events }) => {
      if (status.isInBlock) {
        toast.success(`Transaction successful! Hash: ${status.asInBlock.toHex()}`);
      } else if (status.isFinalized) {
        toast.success("Transaction finalized.");
      } else {
        toast.success(`Transaction status: ${status.type}`);
      }

      events.forEach(({ phase, event: { data, method, section } }) => {
        setTransactionUpdates(`First approval done for ${data.hash}`);
        console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
      });
    });

    otherSuccess && otherSuccess();

    // reset({
    //   proxiedAddress: senderDetails?.ms_address || "",
    //   proxiedName: "",
    //   amount: "",
    // });
    // closeModal();
    // setEncodedCallData("");
    setTransactionUpdates(`First approval done for ${data.hash}`);
  } catch (err) {
    otherError && otherError();
    setTransactionUpdates(`Error occurred for ${data.hash}`);
    toast.error(`Transaction failed: ${err.message}`);
    console.error(err);
  }
};
