import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { removeAgreementApi } from "../../Api/ApiList";
import { usePerformMutation } from "../../Api/PerformMutation";
import { useBudget } from "../../Context/BudgetContext";

const DeleteAgreementModal = () => {
  const { toggleAgreementDeleteModal, deleteAgreementModal, refetch, data, uploadId } = useBudget();
  const { pathname } = useLocation();
  const type = pathname.split("/")[pathname.split("/")?.length - 1] || "bank_payments";
  const [loading, setLoading] = useState(false);

  const { mutate } = usePerformMutation(() => {
    refetch();
  });

  const handleRemoveAgreement = (id) => {
    setLoading(true);
    const singleEditData = data?.data?.find((item) => item?.id === uploadId);
    if (singleEditData) {
      mutate({ url: removeAgreementApi, data: { ...singleEditData, signed_agreement: null, type: type } });
    }
    setLoading(false);
    toggleAgreementDeleteModal();
  };

  return (
    <Modal isOpen={deleteAgreementModal} toggle={toggleAgreementDeleteModal} className="account-deletion" centered>
      <ModalBody>
        <div className="trash-box">
          <i className="fa-solid fa-trash-can"></i>
          <h3>Do you really want to remove this Agreement ?</h3>
          <p>This operation will permanently remove the item and is not reversible. Are you sure you want to proceed?</p>
        </div>
      </ModalBody>
      <ModalFooter className="pt-0">
        <div className="common-btn-box">
          <Button color="danger" onClick={() => handleRemoveAgreement(uploadId)} disabled={loading}>
            Delete {loading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
          </Button>
          <Button color="secondary" onClick={toggleAgreementDeleteModal} disabled={loading}>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteAgreementModal;
