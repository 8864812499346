import { web3FromSource } from "@polkadot/extension-dapp";
import { hexToU8a } from "@polkadot/util";
import { blake2AsHex } from "@polkadot/util-crypto";
import { BN } from "bn.js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useMultisig } from "../../../Context/MultisigContext";
import { useWallet } from "../../../Context/WalletContext";
import { handleCopyCode } from "../../../Utils";
import { generateEncodedCallData, getInjectorMetadata } from "../../Common/AvailCommon";
import { checkJsonFormat } from "../../Common/CustomFunctions";

const ProxyModal = ({ isOpen, toggle, senderDetails }) => {
  const [loading, setLoading] = useState(false);
  const { setTransactionUpdates } = useMultisig();
  const [encodedCallData, setEncodedCallData] = useState(""); // New state for encoded call data
  const { api } = useWallet();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      proxiedAddress: "", // Leave it empty initially
      callData: "",
      callHash: "",
      currentAddress: "",
    },
  });

  const closeModal = () => {
    toggle("proxy");
  };

  const getCreatePureCallData = async () => {
    if (!api) {
      toast.error("Please ensure that API is connected");
      return;
    }

    try {
      // Step 1: Generate call data
      const createPureCall = api.tx.proxy.createPure("Any", 0, 0);

      // Step 2: Convert to Hex
      const hexCallData = createPureCall.method.toHex();

      // Step 3: Generate the Hash
      const callHash = blake2AsHex(hexToU8a(hexCallData));

      // Store in State or use in Transactions
      setEncodedCallData(callHash);
      return {
        hexCallData,
        callHash,
      };
    } catch (error) {
      console.error("Error generating createPure call data:", error);
      toast.error("Failed to generate proxy call hash");
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    // Convert amount to the required large unit (1e18)
    const amountInWei = new BN(String(data.amount).replace(/,/g, "")); // Remove commas, convert to BN
    const amountInLargeUnit = amountInWei.mul(new BN("1000000000000000000")); // Multiply by 1e18

    // Call generateEncodedCallData with the necessary parameters
    const generatedData = await generateEncodedCallData(api, "balances", "transferKeepAlive", [
      { Id: data.proxiedName }, // or directly use data.proxiedAddress
      amountInLargeUnit, // Use the amount in large unit
    ]);

    // Set the generated encoded call data in the state
    setEncodedCallData(generatedData);
    handleCopyCode(generatedData, "Call data");
    setLoading(false);
  };

  const signTransaction = async (data) => {
    console.log("🚀 ~ signTransaction ~ data:", data);
    if (!api) {
      toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
      return;
    }
    try {
      const injector = await web3FromSource("subwallet-js");
      if (!api) await injector.metadata.provide(getInjectorMetadata(api));
      const fromAddress = data?.currentAddress;

      const otherSignatories = senderDetails?.members
        ? JSON.parse(senderDetails.members)
            .filter((member) => member.address !== fromAddress) // Exclude the sender's address
            .map((member) => member.address)
            .sort() // Must be sorted
        : [];

      const transfer = api.tx.multisig.approveAsMulti(Number(senderDetails?.threshold), otherSignatories, null, data.callHash, {
        refTime: 196085000,
        proofSize: 3593,
      });

      await transfer.signAndSend(fromAddress, { signer: injector.signer }, ({ status, events }) => {
        if (status.isInBlock) {
          toast.success(`Transaction successful! Hash: ${status.asInBlock.toHex()}`);
        } else if (status.isFinalized) {
          toast.success("Transaction finalized.");
        } else {
          toast.success(`Transaction status: ${status.type}`);
        }

        events.forEach(({ phase, event: { data, method, section } }) => {
          setTransactionUpdates(`First approval done for ${data.hash}`);
          console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
        });
      });

      // reset({
      //   proxiedAddress: senderDetails?.ms_address || "",
      //   proxiedName: "",
      //   amount: "",
      // });
      closeModal();
      setEncodedCallData("");
      setTransactionUpdates(`First approval done for ${data.hash}`);
    } catch (err) {
      setTransactionUpdates(`Error occurred for ${data.hash}`);
      toast.error(`Transaction failed: ${err.message}`);
      console.error(err);
    }
  };

  // async function checkProxies(multisigAddress) {
  //   const proxies = await api.query.proxy.proxies(multisigAddress);
  //   console.log("proxyssssss", proxies.toHuman());
  // }

  // // Usage example
  // checkProxies("5CSSHUyHZoZVtp27mdS6bsMscQTqoJnA5W7u1oqQR1eZpBQb")
  //   .then((hasProxy) => console.log("Has proxied account:", hasProxy))
  //   .catch(console.error);

  const handleCancel = () => {
    closeModal();
    setEncodedCallData("");
    reset({
      proxiedAddress: senderDetails?.ms_address || "",
      proxiedName: "",
      callData: "",
    });
  };

  useEffect(() => {
    if (!api) {
      toast.error(`Please ensure api is connected`);
      return;
    }
    setValue("proxiedAddress", senderDetails?.ms_address);
    const fetchCallData = async () => {
      const { hexCallData, callHash } = await getCreatePureCallData();
      setValue("callData", hexCallData);
      setValue("callHash", callHash);
    };

    fetchCallData();
  }, [senderDetails]);

  return (
    <Modal size="lg" centered isOpen={isOpen} toggle={closeModal} className="transaction-modal budget-modal">
      <ModalHeader toggle={closeModal}>{"Add Proxied Account"}</ModalHeader>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(signTransaction)}>
        <ModalBody>
          <Row className="g-3">
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Account:</label>
                <input {...register("proxiedAddress", { required: "Proxied account address is required" })} className={`form-control ${errors.proxiedAddress ? "is-invalid" : ""}`} type="text" placeholder="Address being proxied" readOnly />
                <p className="helper-text"> The sending account that will be used to send this transaction. Any applicable fees will be paid by this account.</p>
                {errors.proxiedAddress && <div className="invalid-feedback">{errors.proxiedAddress.message}</div>}
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">* Multisig signatory:</label>
                <select {...register("currentAddress", { required: "Signatory must be selected" })} className={`form-control ${errors.currentAddress ? "is-invalid" : ""}`}>
                  <option value="" disabled>
                    Select address
                  </option>
                  {checkJsonFormat(senderDetails?.members)?.map((member, index) => (
                    <option key={index} value={member?.address}>
                      {member?.address}
                    </option>
                  ))}
                </select>
                <p className="helper-text">The signatory is one of the allowed accounts on the multisig, making a recorded approval for the transaction.</p>
                {errors.currentAddress && <div className="invalid-feedback">{errors.currentAddress.message}</div>}
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Call data:</label>
                <input disabled {...register("callData", { required: "Signatory must be selected" })} className={`form-control ${errors.callData ? "is-invalid" : ""}`} type="text" placeholder="Call data for approval" readOnly />
                <p className="helper-text"> The full call data that can be supplied to a final call to multi approvals</p>
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Call hash:</label>
                <input disabled {...register("callHash", { required: "Signatory must be selected" })} className={`form-control ${errors.callHash ? "is-invalid" : ""}`} type="text" placeholder="Call data for approval" readOnly />
                <p className="helper-text"> The call hash as calculated for this transaction</p>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={loading}>
            {loading ? "Adding..." : "Add"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ProxyModal;
