import React, { useEffect } from "react";
import ForgotPasswordContainer from "../../../Components/Auth/ForgotPassword";
import { Col, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { forgotToken } = location.state || {};

  useEffect(() => {
    if (!forgotToken) {
      navigate(`${process.env.PUBLIC_URL}/login`);
    }
  }, [forgotToken, navigate]);
  
  if (!forgotToken) {
    return null;
  }

  return (
    <div className="login-section">
      <Row className="login-content align-items-center g-0">
        <Col xs="5" className="mx-auto">
          <div className="login-main">
            <ForgotPasswordContainer />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
