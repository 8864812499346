import React, { Fragment, useEffect, useState } from "react";
import { Plus } from "react-feather";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { deleteMultisigApi, getMultisigApi } from "../../Api/ApiList";
import { useGetApi } from "../../Api/useGetApi";
import { useBridgerTransfer } from "../../Context/BridgeTransferContext";
import { useMultisig } from "../../Context/MultisigContext";
import { useWallet } from "../../Context/WalletContext";
import useDebounce from "../../Hooks/useDebounce";
import Loader from "../../Layout/Loader";
import { handleCopyCode } from "../../Utils";
import { getAvailBalance } from "../Common/AvailCommon";
import CommonPagination from "../Common/CommonPagination";
import CommonTooltip from "../Common/CommonTooltip";
import { checkJsonFormat } from "../Common/CustomFunctions";
import DeleteModal from "../Common/DeleteModal";
import TableTop from "../Common/TableTop";
import AddMultisigModal from "./Modals/AddMutisigModal";
import AddProxiedModal from "./Modals/AddProxiedModal";
import PendingTransactionModal from "./Modals/PendingTransactionModal";
import ProxyModal from "./Modals/ProxyModal";
import SendAvailModal from "./Modals/SendAvailModal";
import ProxiedAddressCard from "./ProxiedAddressCard";
import MultisigAddressCard from "./MultisigAddressCard";

const MultisigContainer = () => {
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const { api } = useWallet();
  const [limit, setLimit] = useState(25);
  const [multisigTransactions, setPendingMultisigTransactions] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const { transactionUpdates } = useMultisig();
  // const [edit, destroy] = usePermissionCheck(["edit", "destroy", "create"], "multisig");
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [sendAddress, setSendAddress] = useState("");
  // const [proxiedAccounts, setProxiedAccounts] = useState([]);
  const { proxiedMultisigAccounts, setProxiedMultisigAccounts } = useMultisig();

  const [modalState, setModalState] = useState({
    addProxy: false,
    hashes: false,
    callData: false,
    proxy: false,
  });

  // Unified modal toggle function
  const toggleModal = (modalName) => {
    setModalState((prev) => ({
      ...prev,
      [modalName]: !prev[modalName],
    }));
  };
  // const toggleCallDataModal = () => setCallDataModal((prev) => !prev);
  const toggleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const { searchTerm, setSearchTerm } = useBridgerTransfer();
  const search = useDebounce(searchTerm?.trim(), 500);
  const [params, setParams] = useState({});
  const [pendingTransactionCounts, setPendingTransactionCounts] = useState({});
  const { editModal, toggleEdit, refetchWalletAccounts, toggleDelete, deleteModal, setEditMultisigData, editMultisigData } = useMultisig();
  const handleEdit = (id) => {
    setEditMode(true);
    const singleEditData = multisigData?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleEdit();
      setEditMultisigData(singleEditData);
    }
  };

  const handleDelete = (id) => {
    const singleEditData = multisigData?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleDelete();
      setEditMultisigData(singleEditData);
    }
  };

  const { data: multisigData, refetch: refetchMultisig, isLoading } = useGetApi({ url: getMultisigApi, params, options: { enabled: !!Object.keys(params)?.length } });
  const getPendingMultisigTransactions = async (address) => {
    try {
      const entries = await api.query.multisig.multisigs.entries(address);

      if (entries.length === 0) {
        return [];
      }

      // Loop through the pending transactions
      return entries.map(([key]) => key.args[1].toString());
    } catch (error) {
      console.error("Error fetching pending multisig transactions:", error);
      return []; // Return an empty array in case of error
    }
  };

  const checkProxiedAccount = async (delegatorAddress) => {
    if (!api) {
      console.error("API is not connected.");
      return;
    }

    try {
      const proxies = await api.query.proxy.proxies(delegatorAddress);

      if (proxies[0].length === 0) {
        return null;
      }

      return proxies[0];
    } catch (error) {
      console.error("Error checking proxied account:", error);
    }
  };

  // Example Usage
  const multisigAddress = "5CSSHUyHZoZVtp27mdS6bsMscQTqoJnA5W7u1oqQR1eZpBQb"; // Replace with the actual multisig address
  checkProxiedAccount(multisigAddress);

  const handleSend = (item, modal) => {
    setSendAddress(item);
    setTimeout(() => {
      toggleModal(modal);
    }, 500);
  };

  const handlePendingTransaction = (item) => {
    toggleModal("hashes");
    setSendAddress(item);
  };

  useEffect(() => {
    if (searchTerm?.trim() === "") {
      setPage(1);
    }
  }, [searchTerm]);

  // total pages of data
  useEffect(() => {
    if (multisigData?.data?.length) {
      setTotalPages(multisigData.totalPages);
    }
  }, [multisigData]);

  useEffect(() => {
    // Check if `multisigData` and `api` are ready
    if (multisigData?.data?.length && api) {
      const fetchPendingTransactions = async () => {
        try {
          // Fetch pending transactions for all addresses
          const pendingMultisigTransactions = await Promise.all(
            multisigData.data.map(async (item) => {
              const transactions = await getPendingMultisigTransactions(item.ms_address);
              return {
                address: item.ms_address,
                transactions,
              };
            })
          );

          // Convert to a map with address as the key
          const transactionsMap = pendingMultisigTransactions.reduce((acc, item) => {
            acc[item.address] = item.transactions;
            return acc;
          }, {});

          // Update the state once all data is ready
          setPendingTransactionCounts(transactionsMap);
        } catch (error) {
          console.error("Error fetching pending transactions:", error);
        }
      };

      fetchPendingTransactions();
    }
  }, [multisigData, api, transactionUpdates]); // Trigger only when `multisigData` or `api` changes

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page,
      limit,
      search,
    }));
  }, [search, page, limit]);

  useEffect(() => {
    if (search?.trim().length > 0) {
      isLoading && refetchMultisig();
    }
  }, [search, page, limit, isLoading, refetchMultisig]);

  useEffect(() => {
    refetchWalletAccounts();
    const existingProxiedAccounts = JSON.parse(localStorage.getItem("proxiedData"));
    setProxiedMultisigAccounts(existingProxiedAccounts);
  }, []);

  const [balances, setBalances] = useState({}); // Store balances by address

  const fetchBalances = async () => {
    try {
      const balanceData = await Promise.all(
        [...multisigData?.data, ...(proxiedMultisigAccounts || [])]?.map(async (item) => {
          const balance = await getAvailBalance(api, item?.ms_address || item?.proxiedAddress);
          return { address: item?.ms_address || item?.proxiedAddress, balance };
        })
      );
      // Convert the result to a key-value pair for quick lookup
      const balanceMap = balanceData.reduce((acc, item) => {
        acc[item.address] = item.balance;
        return acc;
      }, {});
      setBalances(balanceMap);
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

  const checkIfProxied = async (address) => {
    try {
      if (!api) {
        console.error("API not initialized");
        return false;
      }

      if (!address || typeof address !== "string" || address.length !== 48) {
        console.error("Invalid address format:", address);
        return false;
      }

      const proxies = await api.query.proxy.proxies(address);
      console.log("Proxies response:", proxies.toHuman()); // Debugging

      // Ensure proxies data is properly structured
      const [proxyList] = proxies.toHuman();
      if (!proxyList || proxyList.length === 0) {
        return false;
      }

      return true;
    } catch (error) {
      console.error("Error fetching proxy data:", error);
      return false;
    }
  };

  useEffect(() => {
    if (multisigData?.data?.length && api) {
      fetchBalances();
    }
  }, [multisigData, api, transactionUpdates, proxiedMultisigAccounts]);

  if (isLoading) return <Loader />;
  return (
    <Fragment>
      <section className="common-table">
        <Card>
          <CardHeader className="d-flex justify-content-between">
            <h4>Multisig</h4>
            <div>
              <Button className="custom-primary-btn me-2" onClick={toggleEdit}>
                <Plus size={15} />
                Add Multisig
              </Button>
              <Button className="custom-primary-btn" onClick={() => toggleModal("addProxy")}>
                <Plus size={15} />
                Proxied
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <TableTop limit={limit} searchTerm={searchTerm} setLimit={setLimit} setPage={setPage} setSearchTerm={setSearchTerm} />
            <MultisigAddressCard balances={balances} handlePendingTransaction={handlePendingTransaction} handleSend={handleSend} multisigData={multisigData} toggleDropdown={toggleDropdown} setEditMode={setEditMode} pendingTransactionCounts={pendingTransactionCounts} openDropdownId={openDropdownId} />
            <ProxiedAddressCard balances={balances} openDropdownId={openDropdownId} toggleDropdown={toggleDropdown} handleSend={handleSend} />
            {!isLoading && multisigData?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
          </CardBody>
        </Card>
      </section>
      {editModal && <AddMultisigModal isEditMode={editMode} refetchMultisig={refetchMultisig} setEditMode={setEditMode} />}
      {deleteModal && <DeleteModal isOpen={deleteModal} deleteEndpoint={deleteMultisigApi} itemData={{ id: editMultisigData?.id }} itemType="Multisig" toggleModal={toggleDelete} refetchCallback={refetchMultisig} />}
      <SendAvailModal senderDetails={sendAddress} toggle={toggleModal} isOpen={modalState?.callData} />
      <PendingTransactionModal setPendingMultisigTransactions={setPendingMultisigTransactions} multisigTransactions={multisigTransactions} toggle={toggleModal} isOpen={modalState?.hashes} senderDetails={sendAddress} />
      <AddProxiedModal checkIfProxied={checkIfProxied} isOpen={modalState?.addProxy} senderDetails={sendAddress} toggle={toggleModal} />
      <ProxyModal isOpen={modalState?.proxy} senderDetails={sendAddress} toggle={toggleModal} />
    </Fragment>
  );
};

export default MultisigContainer;
