import React from "react";
import { Link } from "react-router-dom";

const SearchbarResult = ({ linkItems, searchQuery }) => {
  return (
    <>
      <div className={`Typeahead-menu custom-scrollbar ${linkItems?.length ? "is-open" : ""}`} id="search-outer">
        <div className="header-search-suggestion custom-scrollbar">
          {linkItems?.length
            ? linkItems.map((item, i) => (
                <div className="ProfileCard u-cf" key={i}>
                  <div className="ProfileCard-details">
                    <div className="ProfileCard-realName">
                      <Link className="realname  w-100 d-flex justify-content-start gap-2" to={item?.path}>
                        {item?.icon}
                        {item?.title || item?.name}
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default SearchbarResult;
