import React, { createContext, useContext, useState } from "react";
import { getWalletAccountsApi } from "../Api/ApiList";
import { useGetApi } from "../Api/useGetApi";

const MultisigContext = createContext();

export const MultisigProvider = ({ children }) => {
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleEdit = () => setEditModal((prev) => !prev);
  const toggleDelete = () => setDeleteModal((prev) => !prev);
  const [transactionUpdates, setTransactionUpdates] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentModal, setCurrentModal] = useState("connect");
  const [accounts, setAccounts] = useState([]);
  const [proxiedMultisigAccounts, setProxiedMultisigAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [editMultisigData, setEditMultisigData] = useState({});
  const { data, refetch: refetchWalletAccounts } = useGetApi({ url: getWalletAccountsApi, options: { enabled: false, refetchOnMount: false, refetchOnWindowFocus: false } });

  return <MultisigContext.Provider value={{ setProxiedMultisigAccounts, proxiedMultisigAccounts, refetchWalletAccounts, transactionUpdates, setTransactionUpdates, toggleDelete, deleteModal, setEditMultisigData, editMultisigData, data, setSelectedAccount, selectedAccount, setAccounts, accounts, setCurrentModal, currentModal, editModal, setEditModal, toggleEdit, searchTerm, setSearchTerm }}>{children}</MultisigContext.Provider>;
};
export const useMultisig = () => {
  return useContext(MultisigContext);
};
