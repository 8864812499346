import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";

export const performMutation = async ({ url, data, extraData }) => {
  const token = localStorage.getItem("finance_token");
  const parsedToken = JSON.parse(token);

  // Create FormData instance
  const formData = new FormData();
  formData.append("file", data); // Assuming 'data' is the file
  formData.append("type", extraData?.type); // Add other fields as necessary
  if (extraData?.id) formData.append("id", extraData?.id); // Add other fields as necessary

  // Send formData with the POST request
  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${url}`, formData, {
    headers: {
      "x-access-token": parsedToken,
    },
  });

  return response.data;
};

const useImport = ({ url, refetch, extraFun }) => {
  return useMutation({
    mutationFn: ({ data, extraData }) => performMutation({ url, data, extraData }), // Include extraData
    onSuccess: (resData) => {
      const handleSnackbar = () => {
        toast.success(resData?.message);
        if (extraFun) {
          extraFun();
        } else {
          toast.error("Error importing file");
        }
      };

      if (refetch) {
        refetch().then(handleSnackbar);
      }
    },
    onError: () => {
      toast.error("Error importing file");
    },
  });
};

export default useImport;
