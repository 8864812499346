import React from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";

const RecentTreasury = ({ staticsData }) => {
  return (
    <Col md="12">
      <Card>
        <CardHeader>
          <h4>Recent Treasury Transfers</h4>
        </CardHeader>
        <CardBody>
          <div className="table-responsive custom-scroll">
            <Table className="data-transfer-table">
              <thead className="border-bottom">
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {staticsData?.recentTransaction?.length > 0 ? (
                  staticsData?.recentTransaction?.map((item) => (
                    <tr key={item.id}>
                      <td>{item.from_account}</td>
                      <td>{item.to_account}</td>
                      <td>{item.amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="">
                    <td colSpan="10" className="text-center no-found">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>  
          </div>
        </CardBody>
      </Card>
    </Col>    
  );
};
export default RecentTreasury;
