import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";

const useDelete = (url, refetchCallback, toggleModalCallback, additionalActions = () => {}) => {
  const [loading, setLoading] = useState(false); // Loading state

  const handleDelete = async (data) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("finance_token");
      const parsedToken = JSON.parse(token);
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}${url}`, {
        headers: {
          "x-access-token": parsedToken,
        },
        data, // Pass the ID dynamically
      });

      if (response.status === 200) {
        toast.success(response.data.message);
        refetchCallback(); // Trigger the refetch function passed in as a parameter
        toggleModalCallback(); // Close the modal after deletion
        additionalActions(); // Perform any additional actions (optional)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleDelete };
};

export default useDelete;
