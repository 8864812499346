import { web3FromSource } from "@polkadot/extension-dapp";
import { hexToU8a } from "@polkadot/util";
import { blake2AsHex } from "@polkadot/util-crypto";
import { BN } from "bn.js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useMultisig } from "../../../Context/MultisigContext";
import { useWallet } from "../../../Context/WalletContext";
import { handleCopyCode } from "../../../Utils";
import { generateEncodedCallData, getInjectorMetadata } from "../../Common/AvailCommon";

const AddProxiedModal = ({ isOpen, toggle, senderDetails,checkIfProxied }) => {
  const [loading, setLoading] = useState(false);
  const { setTransactionUpdates, setProxiedMultisigAccounts } = useMultisig();
  const [encodedCallData, setEncodedCallData] = useState(""); // New state for encoded call data
  const { api } = useWallet();

  const {
    register,
    handleSubmit,
    reset,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      proxiedAddress: "", // Leave it empty initially
      proxiedName: "",
      amount: "",
    },
  });

  const closeModal = () => {
    toggle("addProxy");
  };

  const onSubmit = async (data) => {
    setLoading(true);

    // Store new data in localStorage
    const storedData = JSON.parse(localStorage.getItem("proxiedData")) || []; // Get existing data or empty array
    storedData.push({ ...data }); // Append new entry
    setProxiedMultisigAccounts(storedData);
    localStorage.setItem("proxiedData", JSON.stringify(storedData)); // Save updated data

    setLoading(false);
    closeModal();
    toast.success("Proxied data added successfully!");
    reset();
  };

  const signTransaction = async (data) => {
    if (!api) {
      toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
      return;
    }
    try {
      const injector = await web3FromSource("subwallet-js");
      if (!api) await injector.metadata.provide(getInjectorMetadata(api));
      const fromAddress = data?.proxiedName;
      const callHash = await blake2AsHex(hexToU8a(encodedCallData));

      const otherSignatories = senderDetails?.members
        ? JSON.parse(senderDetails.members)
            .filter((member) => member.address !== fromAddress) // Exclude the sender's address
            .map((member) => member.address)
            .sort() // Must be sorted
        : [];

      const transfer = api.tx.multisig.approveAsMulti(Number(senderDetails?.threshold), otherSignatories, null, callHash, {
        refTime: 196085000,
        proofSize: 3593,
      });

      await transfer.signAndSend(fromAddress, { signer: injector.signer }, ({ status, events }) => {
        if (status.isInBlock) {
          toast.success(`Transaction successful! Hash: ${status.asInBlock.toHex()}`);
        } else if (status.isFinalized) {
          toast.success("Transaction finalized.");
        } else {
          toast.success(`Transaction status: ${status.type}`);
        }

        events.forEach(({ phase, event: { data, method, section } }) => {
          setTransactionUpdates(`First approval done for ${data.hash}`);
          console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
        });
      });

      reset({
        proxiedAddress: senderDetails?.ms_address || "",
        proxiedName: "",
        amount: "",
      });
      closeModal();
      setEncodedCallData("");
      setTransactionUpdates(`First approval done for ${data.hash}`);
    } catch (err) {
      setTransactionUpdates(`Error occurred for ${data.hash}`);
      toast.error(`Transaction failed: ${err.message}`);
      console.error(err);
    }
  };

  const handleCancel = () => {
    closeModal();
    setEncodedCallData("");
    reset({
      proxiedAddress: senderDetails?.ms_address || "",
      proxiedName: "",
      amount: "",
    });
  };

  

  const proxiedAddress = watch("proxiedAddress");

  useEffect(() => {
    if (api && proxiedAddress) {
      checkIfProxied(proxiedAddress).then((isProxied) => {
        console.log("🚀 ~ checkIfProxied ~ isProxied:", isProxied);
        if (!isProxied) {
          setError("proxiedAddress", {
            type: "manual",
            message: "This address is not proxied",
          });
        } else {
          setError("proxiedAddress", {
            type: "manual",
            message: "",
          });
        }
      });
    }
  }, [proxiedAddress, api]);

  return (
    <Modal size="lg" centered isOpen={isOpen} toggle={closeModal} className="transaction-modal budget-modal">
      <ModalHeader toggle={closeModal}>{"Add Proxied Account"}</ModalHeader>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Row className="g-3">
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">* Proxied Account:</label>
                <input
                  {...register("proxiedAddress", {
                    required: "Proxied account address is required",
                  })}
                  className={`form-control ${errors.proxiedAddress?.message ? "is-invalid" : ""}`}
                  type="text"
                  placeholder="Address being proxied"
                />
                <p className="helper-text"> The address that has previously setup a proxy to one of the accounts that you control.</p>
                {errors.proxiedAddress && <div className="invalid-feedback">{errors.proxiedAddress.message}</div>}
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">* Name:</label>
                <input {...register("proxiedName", { required: "Proxied name is required" })} className={`form-control ${errors.proxiedName ? "is-invalid" : ""}`} type="text" placeholder="Proxied name" />
                <p className="helper-text">The name is for unique identification of the account in your owner lists.</p>
                {errors.proxiedName && <div className="invalid-feedback">{errors.proxiedName.message}</div>}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={loading}>
            {loading ? "Adding..." : "Add"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddProxiedModal;
