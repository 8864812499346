import { WsProvider } from "@polkadot/rpc-provider";
import { initialize } from "avail-js-sdk";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { useAuth } from "../Context/AuthContext";
import { useWallet } from "../Context/WalletContext";
import { useHeaderScroll } from "../Hooks/useHeaderScroll";
import HeaderNotifications from "./HeaderNotifications";
import Sidebar from "./Sidebar/Sidebar";
import ConnectWalletModal from "./WalletLayout/ConnectWalletModal";
import SearchbarResult from "./Searchbar";
import { extractLinkMenus } from "../Components/Common/CustomFunctions";
import { MenuList } from "../Data/Layout/SidebarData";
import { web3Accounts, web3Enable } from "@polkadot/extension-dapp";
import { usePerformMutation } from "../Api/PerformMutation";
import { useMultisig } from "../Context/MultisigContext";
import { saveWalletAccountsApi } from "../Api/ApiList";
import toast from "react-hot-toast";

const Layout = () => {
  const { toggleEdit, setApi, accounts, setCurrentModal, setIsExtensionInstalled, selectedAccount, setAccounts, setSelectedAccount } = useWallet();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const navigate = useNavigate();
  const upScroll = useHeaderScroll(false);
  const { logout } = useAuth();
  const [toggle, setToggle] = useState(localStorage.getItem("isDark") === "true");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const { pathname } = useLocation();
  const isWalletConnected = localStorage.getItem("isWalletConnected");
  const { mutate } = usePerformMutation(false, false, false);
  const { refetchWalletAccounts } = useMultisig();

  const handleDarkLight = () => {
    const newTheme = !toggle;
    setToggle(newTheme);
    localStorage.setItem("isDark", newTheme);
    document.body.classList.toggle("dark-only", newTheme);
  };

  useEffect(() => {
    const isDark = localStorage.getItem("isDark") === "true";
    document.body.classList.toggle("dark-only", isDark);
  }, []);

  const handleFullscreen = () => {
    setIsFullScreen(!isFullScreen);
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const connectApi = async () => {
    try {
      const wsProvider = new WsProvider("wss://turing-rpc.avail.so/ws");
      const api = await initialize("wss://turing-rpc.avail.so/ws");
      await api.isReady;
      setApi(api);
    } catch (error) {
      return error;
    }
  };

  const connectWallet = async () => {
    try {
      await web3Enable("Avail Transaction Decoder");

      const allAccounts = await web3Accounts();
      setAccounts(allAccounts);

      if (allAccounts.length) {
        allAccounts?.forEach(async (item) => {
          await mutate({ url: saveWalletAccountsApi, data: { address: item?.address, name: item?.meta?.name } });
          refetchWalletAccounts();
        });
      }

      setIsExtensionInstalled(true);
      localStorage.setItem("isConnectedToWallet", true);

      // Check if the toast has been shown already
      if (!localStorage.getItem("toastShown")) {
        toast.success("Wallet connected successfully");
        localStorage.setItem("toastShown", "true"); // Mark that toast has been shown
      }
    } catch (error) {
      console.error("Failed to connect wallet:", error);
      toast.error("Failed to connect wallet:", error);
      alert(error);
    }
  };

  useEffect(() => {
    connectApi()
      .then(() => {
        if (localStorage.getItem("isConnectedToWallet")) {
          connectWallet();
        }
      })
      .then(() => {
        setSelectedAccount(JSON.parse(localStorage.getItem("selectedAccount")));
      })
      .catch((error) => {
        toast.error("Some error occurred while connecting", error);
      });
  }, [isWalletConnected]);

  const [isOpen, setIsOpen] = useState(true);

  const toggleClick = () => {
    document.querySelector(".page-header")?.classList.toggle("close_icon");
    document.querySelector(".sidebar-wrapper")?.classList.toggle("close_icon");
    setIsOpen(!isOpen);
  };

  const detectBlockchainWallet = () => {
    if (typeof window !== "undefined") {
      return !!(window.ethereum || window.web3);
    }
    return false;
  };

  const searchMenu = (menuList, query) => {
    const lowerCaseQuery = query.toLowerCase();
    if (lowerCaseQuery.trim() === "") {
      setSearchResults([]); // Clear results when input is empty
      return;
    }
    const linkMenus = extractLinkMenus(menuList);
    const filteredResults = linkMenus.filter((menu) => menu.title.toLowerCase().includes(lowerCaseQuery));
    setSearchResults(filteredResults);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const results = searchMenu(MenuList, query);
    setFilteredResults(results);
  };

  useEffect(() => {
    const compactSidebar = () => {
      const windowWidth = window.innerWidth;
      const action = windowWidth < 1200 ? "add" : "remove";
      document.querySelector(".page-header")?.classList[action]("close_icon");
      document.querySelector(".sidebar-wrapper")?.classList[action]("close_icon");
      const activeSidebar = windowWidth < 992 ? "add" : "remove";
      document.querySelector(".page-header")?.classList[activeSidebar]("close_icon");
      document.querySelector(".sidebar-wrapper")?.classList[activeSidebar]("close_icon");
    };

    compactSidebar();
    window.addEventListener("resize", compactSidebar);
    const foundExtension = detectBlockchainWallet();
    setIsExtensionInstalled(foundExtension);

    return () => window.removeEventListener("resize", compactSidebar);
  }, []);

  useEffect(() => {
    setSearchQuery("");
    setSearchResults([]);
  }, [pathname]);

  const handleModalButtonClick = () => {
    if (accounts?.length) {
      setCurrentModal("account");
    }
    toggleEdit();
  };

  return (
    <div className={`page-wrapper`}>
      <ConnectWalletModal connectWallet={connectWallet} />
      <div className={`page-body-wrapper`}>
        <div className={`page-header ${isSidebarOpen ? "close_icon" : ""}`}>
          <Container fluid className={`${upScroll ? "sticky" : ""} main-navbar d-flex justify-content-between container-fluid p-0`}>
            <div className="toggle-sidebar" onClick={toggleClick}>
              <div className="sidebar-toggle">
                <i className="fa-solid fa-bars"></i>
              </div>
            </div>
            <div className="header-search">
              <input type="text" className="search-bar" value={searchQuery} onChange={handleSearchChange} placeholder="Search....." />
              <SearchbarResult linkItems={searchResults} searchQuery={searchQuery} />
            </div>
            <ul className={`common-flex right-navbar`}>
              {selectedAccount?.meta?.name && (
                <p className="custom-user-btn">
                  <img src="../assets/svg/sub-wallet-icon.svg" alt="" /> {selectedAccount?.meta?.name}
                </p>
              )}
              <Button className="btn btn-primary" onClick={handleModalButtonClick}>
                {accounts?.length ? "Accounts" : "Connect Wallet"}
              </Button>
              <li onClick={handleFullscreen} className={`light-box ${toggle ? "active" : ""}`}>
                {!isFullScreen ? <i className="fa-solid fa-compress"></i> : <i className="fa-solid fa-expand"></i>}
              </li>
              <li onClick={handleDarkLight} className={`light-box ${toggle ? "active" : ""}`}>
                {toggle ? <img className="img-dark" src={`../assets/svg/moon.svg`} alt="" /> : <img src={`../assets/svg/sun.svg`} alt="" />}
              </li>
              <HeaderNotifications />
              <li>
                <Button className="signout" color="dark" onClick={() => logout(navigate)}>
                  Sign Out
                </Button>
              </li>
            </ul>
          </Container>
        </div>

        <div className="page-body">
          <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
          <div className="page-main-body">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
