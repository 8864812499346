import React from "react";
import { checkJsonFormat } from "../Common/CustomFunctions";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { handleCopyCode } from "../../Utils";
import CommonTooltip from "../Common/CommonTooltip";
import { useMultisig } from "../../Context/MultisigContext";

const MultisigAddressCard = ({ multisigData, pendingTransactionCounts, setEditMode,handleSend,handlePendingTransaction,balances,toggleDropdown,openDropdownId }) => {
  const { editModal, toggleEdit, refetchWalletAccounts, toggleDelete, deleteModal, setEditMultisigData, editMultisigData } = useMultisig();

  const handleEdit = (id) => {
    setEditMode(true);
    const singleEditData = multisigData?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleEdit();
      setEditMultisigData(singleEditData);
    }
  };

  const handleDelete = (id) => {
    const singleEditData = multisigData?.data?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleDelete();
      setEditMultisigData(singleEditData);
    }
  };
  return (
    <Row>
      <Col xs="12" className="mb-">
        <h5 className=" custom-chain-heading badge-light-success ">
          Multisig<span>on-chain multisig accounts</span>{" "}
        </h5>
      </Col>
      {multisigData?.data?.length ? (
        multisigData?.data?.map((item, i) => (
          <Col xl="6">
            <Card className="custom-multisig badge-light-success">
              <CardBody>
                <div className="multisig"></div>
                <div className="w-full d-flex justify-content-between">
                  <div className="multisig-row">
                    <span className="multisig-title">{item?.ms_name?.toUpperCase()}</span>
                    <div className="d-flex">
                      <span className="shortAddress">{item?.ms_address}</span>
                      <i className="fa-regular fa-copy" style={{ cursor: "pointer" }} onClick={() => handleCopyCode(item?.ms_address)} />
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    {pendingTransactionCounts[item?.ms_address]?.length ? (
                      <div className="position-relative">
                        <Button id="pending-transactions" onClick={() => handlePendingTransaction(item)} size="sm" color="warning" className="custom-send-btn ">
                          <i className="fa-regular fa-clock fa-lg" style={{ color: "#fff" }}></i>
                        </Button>
                        <span className="badge badge-danger transaction-badge ">{pendingTransactionCounts[item?.ms_address]?.length}</span>
                      </div>
                    ) : null}
                    <CommonTooltip placement="top" id={`pending-transactions`} content={"Pending transactions"} color={"primary"} />

                    <Button id="edit-multisig" onClick={() => handleEdit(item?.id)} size="sm" color="primary" className="custom-send-btn custom-primary-btn">
                      <i className="fa-solid fa-pen-to-square"></i>
                    </Button>
                    <CommonTooltip placement="top" id={`edit-multisig`} content={"Edit Multisig"} color={"primary"} />

                    <Button id="delete-multisig" onClick={() => handleDelete(item?.id)} size="sm" color="primary" className="custom-send-btn custom-primary-btn">
                      <i className="fa-solid fa-trash"></i>
                    </Button>
                    <CommonTooltip placement="top" id={`delete-multisig`} content={"Delete Multisig"} color={"primary"} />

                    <Button size="sm" color="primary" className="custom-send-btn " onClick={() => handleSend(item, "callData")}>
                      Send
                      <i className="fa-solid fa-paper-plane ms-2"></i>
                    </Button>
                    <div className="dropdown">
                      <Button id="view-details" onClick={() => toggleDropdown(item?.id)} className="dropdown-toggle" />
                    </div>
                    <CommonTooltip placement="top" id={`view-details`} content={"View Details"} color={"primary"} />
                  </div>
                </div>
                <div className="multi-count">
                  <span>{balances[item?.ms_address] ? balances[item?.ms_address] + " AVAIL" : "Loading..."}</span>
                </div>
                <div className={`multisig-mem ${openDropdownId === item?.id ? "is-open" : ""}`}>
                  <span className="team-title">Members :</span>
                  <div className="d-flex w-100 justify-content-between">
                    <div>
                      {checkJsonFormat(item?.members)?.map((member, index) => (
                        <CommonTooltip classes={"me-3"} key={index} placement="right" id={`TooltipExample-from-${item.id}`} content={member.address} color={"primary"}>
                          {member?.name?.toUpperCase()}
                        </CommonTooltip>
                      ))}
                    </div>
                    <Button size="sm" color="secondary" className="custom-send-btn" outline onClick={() => handleSend(item, "proxy")}>
                      Create Proxy
                      <i className="ms-2 fa-solid fa-universal-access"></i>
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))
      ) : (
        <div className="text-center no-found">No data found</div>
      )}
    </Row>
  );
};

export default MultisigAddressCard;
