import React, { createContext, useContext, useState } from "react";

const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [editModal, setEditModal] = useState(false);
  const toggleEdit = () => setEditModal((prev) => !prev);
  const [importModal, setImportModal] = useState(false);
  const toggleImport = () => setImportModal((prev) => !prev);
  const [editUserData, setEditUserData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDelete = () => setDeleteModal((prev) => !prev);
  const [editMode, setEditMode] = useState(false);

  return <UsersContext.Provider value={{ editMode, setEditMode, deleteModal, setDeleteModal, toggleDelete, editModal, setEditModal, toggleEdit, importModal, setImportModal, toggleImport, setEditUserData, editUserData, searchTerm, setSearchTerm }}>{children}</UsersContext.Provider>;
};

export const useUsers = () => {
  return useContext(UsersContext);
};
