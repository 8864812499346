import React, { createContext, useContext, useState } from "react";

const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleEdit = () => setEditModal((prev) => !prev);
  const toggleDelete = () => setDeleteModal((prev) => !prev);
  const [importModal, setImportModal] = useState(false);
  const toggleImport = () => setImportModal((prev) => !prev);
  const [editAccountData, setEditAccountData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  return <AccountContext.Provider value={{ editModal, setEditModal, deleteModal, toggleDelete, setDeleteModal, toggleEdit, importModal, setImportModal, toggleImport, setEditAccountData, editAccountData, searchTerm, setSearchTerm }}>{children}</AccountContext.Provider>;
};

export const useAccount = () => {
  return useContext(AccountContext);
};
