import React, { Fragment, useEffect, useState } from "react";
import { Plus } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { userDelete } from "../../../Api/ApiList";
import { useGetApi } from "../../../Api/useGetApi";
import { useUsers } from "../../../Context/UsersContext";
import { UsersTableColumnData } from "../../../Data/TableColumnsData";
import useDebounce from "../../../Hooks/useDebounce";
import usePermissionCheck from "../../../Hooks/usePermissionCheck";
import CommonTable from "../../Common/CommonTable";
import DeleteModal from "../../Common/DeleteModal";
import TableTop from "../../Common/TableTop";
import EditModal from "./EditModal";

const UsersContainer = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [sortOrder, setSortOrder] = useState(true);
  const [sortColumn, setSortColumn] = useState("id");
  const [editMode, setEditMode] = useState(false);
  const [create] = usePermissionCheck(["create"], "user");
  const { setEditUserData, editModal, toggleEdit, searchTerm, setSearchTerm, deleteModal, toggleDelete, editUserData } = useUsers();
  const search = useDebounce(searchTerm?.trim(), 500);
  const [params, setParams] = useState({});
  const { isLoading: isLoadingGetData, data: userData, refetch: refetchGetData } = useGetApi({ url: `/api/user`, params, options: { enabled: !!Object.keys(params).length, select: (data) => data?.data }, navigate: navigate });
  const [edit, destroy] = usePermissionCheck(["edit", "destroy", "create"], "user");

  const addUser = () => {
    setEditMode(false);
    toggleEdit();
  };

  const handleEdit = (id) => {
    setEditMode(true);

    const singleEditData = userData?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleEdit();
      setEditUserData(singleEditData);
    }
  };

  const handleDelete = (id) => {
    const singleEditData = userData?.find((item) => item?.id === id);
    if (singleEditData) {
      toggleDelete();
      setEditUserData(singleEditData);
    }
  };

  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder(!sortOrder);
  };

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setPage(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      page,
      limit,
      search,
      sortOrder: sortOrder ? "desc" : "asc",
      sortColumn: sortColumn,
    }));
  }, [search, page, limit, sortOrder, sortColumn]);

  return (
    <Fragment>
      <section className="common-table">
        <Card>
          <CardHeader>
            <h4>Users</h4>
          </CardHeader>
          <CardBody>
            <TableTop
              limit={limit}
              searchTerm={searchTerm}
              setLimit={setLimit}
              setPage={setPage}
              setSearchTerm={setSearchTerm}
              extraComponents={[
                create && {
                  jsx: (
                    <Button color="border border-1" onClick={addUser}>
                      <Plus size={15} /> Add User
                    </Button>
                  ),
                },
              ]}
            />

            <div className="custom-scrollbar table-responsive">
              <CommonTable serialNo columns={UsersTableColumnData} data={userData || []} isLoading={isLoadingGetData} handleSort={handleSort} sortOrder={sortOrder} onDelete={destroy ? handleDelete : false} onEdit={edit ? handleEdit : false} tableClass="transaction-table" />
            </div>
          </CardBody>
        </Card>
      </section>
      {deleteModal && <DeleteModal isOpen={deleteModal} deleteEndpoint={userDelete} itemData={{ id: editUserData?.id }} itemType="Account" toggleModal={toggleDelete} refetchCallback={refetchGetData} />}
      {editModal && <EditModal refetchUser={refetchGetData} isEditMode={editMode} />}
    </Fragment>
  );
};

export default UsersContainer;
