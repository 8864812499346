import React, { createContext, useContext, useState } from "react";

const ExpenseContext = createContext();

export const ExpenseProvider = ({ children }) => {
  const [editModal, setEditModal] = useState(false);
  const toggleEdit = () => setEditModal((prev) => !prev);
  const [importModal, setImportModal] = useState(false);
  const toggleImport = () => setImportModal((prev) => !prev);
  const [expenseData, setExpenseData] = useState({});
  const [searchTerm, setSearchTerm] = useState(null);

  return <ExpenseContext.Provider value={{ editModal, setEditModal, toggleEdit, importModal, setImportModal, toggleImport, setExpenseData, expenseData, searchTerm, setSearchTerm }}>{children}</ExpenseContext.Provider>;
};

export const useExpense = () => {
  return useContext(ExpenseContext);
};
