import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col } from "reactstrap";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="error-wrapper">
      <div className="container">
        <div className="error-heading">
          <img src={`../../assets/svg/403.svg`} alt="" />
        </div>
        <Col md="8" className=" offset-md-2">
          <p className="sub-content">The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved.</p>
        </Col>
        <div>
          <Button size="lg" color="success" onClick={() => navigate("/")}>
            Back To Home Page
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
