// import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetApi } from "../Api/useGetApi";
import { ConvertPermissionArr } from "../Utils";
// import {storePermission} from '../Data/Layout/SidebarData'
const usePermissionCheck = (permissionTypeArr, keyToSearch) => {
  const storePermission = localStorage.getItem("role") && JSON.parse(localStorage.getItem("role"));
  const [ansData, setAnsData] = useState([]);
  const path = useLocation();
  const moduleToSearch = keyToSearch ? keyToSearch : path.split("/")[1];
  const { data, refetch } = useGetApi({ url: "/api/user/self", options: { enabled: false, refetchOnMount: false } });
  useEffect(() => {
    function removeDuplicateObjects(array) {
      const uniqueObjects = [];
      const seenNames = new Set();

      for (const obj of array) {
        if (!seenNames.has(obj.name)) {
          uniqueObjects.push(obj);
          seenNames.add(obj.name);
        }
      }

      return uniqueObjects;
    }

    if (storePermission?.permissions?.length > 0) {
      const securePaths = removeDuplicateObjects(ConvertPermissionArr(storePermission?.permissions));

      setAnsData(permissionTypeArr.map((permissionType) => Boolean(securePaths?.find((permission) => moduleToSearch == permission.name)?.permissionsArr.find((permission) => permission.type == permissionType))));
    } else {
      refetch();
      if (data) {
        const securePaths = ConvertPermissionArr(data?.data?.permissions);
        setAnsData(permissionTypeArr.map((permissionType) => Boolean(securePaths?.find((permission) => moduleToSearch == permission.name)?.permissionsArr.find((permission) => permission.type == permissionType))));
      }
    }
  }, [storePermission]);

  return ansData;
};

export default usePermissionCheck;
