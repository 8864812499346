import React from "react";
import DexVolumeCard from "./DexVolumeCard";
import HoldersDetailCard from "./HoldersDetailCard";
import PriceDetailCard from "./PriceDetailCard";
import VolumeDetailCard from "./VolumeDetailCard";
import { Col, Row } from "reactstrap";

const AvailInfoCards = ({ baseDexVolumeChanges, ethDexVolumeChanges, priceChange, coinMarketData, volumeChange, dexVolumeChanges, baseDexVolume, holdersChangePerDay, holderPercentage, ethVolume }) => {
  return (
    <Row className="avail">
      <Col xxl="2" xl="6"  >
        <PriceDetailCard coinMarketData={coinMarketData} priceChange={priceChange} />
      </Col>
      <Col xxl="2" xl="6" > 
        <VolumeDetailCard coinMarketData={coinMarketData} volumeChange={volumeChange} />
      </Col>
      <Col xxl="2" xl="6" > 
        <HoldersDetailCard holderPercentage={holderPercentage} holdersChangePerDay={holdersChangePerDay} />
      </Col>
      <Col xxl="6" xl="6" >  
        <DexVolumeCard baseDexVolumeChanges={baseDexVolumeChanges} ethDexVolumeChanges={ethDexVolumeChanges} baseDexVolume={baseDexVolume} dexVolumeChanges={dexVolumeChanges} ethVolume={ethVolume} />
      </Col>
    </Row>
  );
};

export default AvailInfoCards;
