import React, { Fragment, useEffect, useState } from "react";
import { Plus } from "react-feather";
import { useLocation } from "react-router-dom";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { deleteBudgetDataApi } from "../../Api/ApiList";
import useNotifications from "../../Api/useNotifications";
import { useBudget } from "../../Context/BudgetContext";
import useDebounce from "../../Hooks/useDebounce";
import usePermissionCheck from "../../Hooks/usePermissionCheck";
import Loader from "../../Layout/Loader";
import CommonPagination from "../Common/CommonPagination";
import DeleteModal from "../Common/DeleteModal";
import TableTop from "../Common/TableTop";
import BudgetFormsModal from "./BudgetFormsModal/index";
import BudgetTables from "./BudgetTables";
import DeleteAgreementModal from "./DeleteAgreementModal";
import ImportModal from "./ImportModal";

const BudgetContainer = () => {
  const { editModal, toggleEdit, type, setParams, deleteModal, toggleImport, setSortColumn, toggleDelete, editBudgetData, refetch, data, setType, isLoading, totalPages, page, setPage, limit, setLimit, searchTerm, setSearchTerm, sortOrder, sortColumn } = useBudget();
  const [editMode, setEditMode] = useState(false);
  const { pathname } = useLocation();
  const tableType = pathname.split("/")[pathname.split("/")?.length - 1] || "bank_payments";
  const [create] = usePermissionCheck(["create"], "budget");

  const { refetchGetData: refetchNotifications } = useNotifications();
  const search = useDebounce(searchTerm?.trim(), 500);

  const addBudget = () => {
    setEditMode(false);
    toggleEdit();
  };

  useEffect(() => {
    setType(pathname?.split("/")[pathname.split("/")?.length - 1]);
    setPage(1);
    setLimit(25);
    setSortColumn("id");
  }, [pathname]);

  useEffect(() => {
    if (type) {
      setParams({ page, limit, type, search, sortOrder: sortOrder ? "asc" : "desc", sortColumn });
    }
  }, [limit, page, search, setParams, sortColumn, sortOrder, type]);

  const breadcrumbItem = type
    ?.split("_")
    ?.map((item) => item?.charAt(0)?.toUpperCase() + item?.slice(1, item?.length))
    ?.join(" ");

  if (isLoading) return <Loader />;

  return (
    <Fragment>
      <section className="common-table budgeting-table">
        <Card>
          <CardHeader>
            <h4>Budgeting / {breadcrumbItem}</h4>
          </CardHeader>
          <CardBody>
            <TableTop
              limit={limit}
              searchTerm={searchTerm}
              setLimit={setLimit}
              setPage={setPage}
              setSearchTerm={setSearchTerm}
              importFile={toggleImport}
              extraComponents={[
                create && {
                  jsx: (
                    <Button color="border border-1" onClick={addBudget}>
                      <Plus size={15} /> Create Budget
                    </Button>
                  ),
                },
              ]}
            />

            <div className="custom-scrollbar table-responsive">
              <BudgetTables setEditMode={setEditMode} data={data} isLoadingGetData={isLoading} />
            </div>
            {!isLoading && data?.data?.length > 0 && <CommonPagination totalPages={totalPages} currentPage={page} setCurrentPage={setPage} />}
          </CardBody>
        </Card>
      </section>
      {deleteModal && <DeleteModal isOpen={deleteModal} toggleModal={toggleDelete} deleteEndpoint={deleteBudgetDataApi} itemData={editBudgetData} itemType="Budget" refetchCallback={refetch} extraFunction={refetchNotifications} />}
      {editModal && <BudgetFormsModal refetchBudget={refetch} isEditMode={editMode} toggleEdit={toggleEdit} type={tableType} />}
      <ImportModal refetchGetData={refetch} />
      <DeleteAgreementModal />
    </Fragment>
  );
};

export default BudgetContainer;
