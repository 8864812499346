import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AccountProvider } from "./Context/AccountContext";
import { AuthProvider } from "./Context/AuthContext";
import { BridgeTransferProvider } from "./Context/BridgeTransferContext";
import { BudgetProvider } from "./Context/BudgetContext";
import { ExpenseProvider } from "./Context/ExpenseContext";
import { RoleProvider } from "./Context/RoleContext";
import { TransactionProvider } from "./Context/TransactionContext";
import { UsersProvider } from "./Context/UsersContext";
import { WalletProvider } from "./Context/WalletContext";
import Routers from "./Routes";
import { CustomToaster } from "./Utils";
import { MultisigProvider } from "./Context/MultisigContext";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <TransactionProvider>
            <AccountProvider>
              <BridgeTransferProvider>
                <BudgetProvider>
                  <ExpenseProvider>
                    <UsersProvider>
                      <WalletProvider>
                        <MultisigProvider>
                          <RoleProvider>
                            <Routers />
                          </RoleProvider>
                        </MultisigProvider>
                      </WalletProvider>
                    </UsersProvider>
                  </ExpenseProvider>
                </BudgetProvider>
              </BridgeTransferProvider>
            </AccountProvider>
          </TransactionProvider>
          <CustomToaster />
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
