import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { userRole, userRoleCreate, userRoleUpdate } from "../../../../Api/ApiList";
import { usePerformMutation } from "../../../../Api/PerformMutation";
import { useGetApi } from "../../../../Api/useGetApi";
import { useRole } from "../../../../Context/RoleContext";
import PermissionsCheckBoxForm from "./PermissionsCheckBoxForm";

const PermissionForm = () => {
  const { editMode, editUserData } = useRole();
  const { refetch: refetchuserRoleData } = useGetApi({
    url: userRole,
    options: {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    reset,

    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
      permissions: [],
    },
  });

  const { mutate } = usePerformMutation(() => {
    refetchuserRoleData();
    reset();
    // toggleEdit();
  });

  const onSubmit = (data) => {
    const apiEndpoint = editMode ? userRoleUpdate : userRoleCreate;
    const conditionalData = {
      id: data.id,
      name: data.name,
      permissions: data.permissions, // Send as array of permission names
    };

    clearErrors("permissions");
    mutate({ url: apiEndpoint, data: conditionalData, isUpdate: editMode });
    navigate("/users/role");
  };

  useEffect(() => {
    if (editMode && editUserData) {
      setValue("id", editUserData?.id || "");
      setValue("name", editUserData.name || "");
      setValue("permissions", editUserData.permissions || []);
    }
  }, [editMode, editUserData, setValue]);

  return (
    <section className="common-table custom-role-form">
      <Row> 
        <Col xxl="9" xl="10" className="m-auto">
          <Card className="d-flex mb-3">
            <CardHeader>
              <h4>{editMode ? "Edit Role" : "Add Role"}</h4>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup className="form-group role-input-group">
                  <Row className="align-items-center">
                    <Col lg="2">
                      <Label className="form-label">Name *</Label>
                    </Col>
                    <Col lg="10">
                      <input {...register("name", { required: "Name is required", validate: (value) => value.trim() !== "" || "Name cannot be empty or just spaces" })} className={`form-control custom-role-input  ${errors.name ? "is-invalid" : ""}`} type="text" placeholder="Role Name" />
                      {errors.name && <div className="text-danger">{errors.name.message}</div>}
                    </Col>
                  </Row>
                </FormGroup>

                <PermissionsCheckBoxForm errors={errors} setFieldValue={setValue} values={watch} />
                <div className="role-form-button d-flex justify-content-end">
                  <Button type="submit">Save</Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default PermissionForm;
