export const csvData = {
  bank_payments: [
    {
      date: "2024-09-23",
      type_of_transfer: "Local transfer",
      from: "Avail",
      to: "Mohammad Al Baik",
      bank_details: "Emirates NBD",
      confirmation: "R1234567",
      expense_category: "Marketing Campaign",
      type_of_expense: "Recurring",
      currency: "AED",
      amount: 60000,
      status: "Paid",
    },
    {
      date: "2024-09-23",
      type_of_transfer: "International Transfer",
      from: "Avail",
      to: "Oppa Gangnam Style",
      bank_details: "Citibank",
      confirmation: "EB789001",
      expense_category: "Events",
      type_of_expense: "One-time payment",
      currency: "USD",
      amount: 2000,
      status: "Paid",
    },
    {
      date: "2024-01-11",
      type_of_transfer: "International Transfer",
      from: "Avail",
      to: "Jai Ho",
      bank_details: "Metropolitan Commercial Bank",
      confirmation: "Ae123456",
      expense_category: "Service Provider",
      type_of_expense: "Recurring",
      currency: "GBP",
      amount: 1250,
      status: "Paid",
    },
    {
      date: "2024-01-11",
      type_of_transfer: "Local transfer",
      from: "Avail",
      to: "Habibi come to Dubai",
      bank_details: "ADCB",
      confirmation: "123457",
      expense_category: "Salary",
      type_of_expense: "Recurring",
      currency: "AED",
      amount: 20000,
      status: "Paid",
    },
    {
      date: "2024-09-12",
      type_of_transfer: "Local transfer",
      from: "Avail",
      to: "Jollibee",
      bank_details: "ADCB",
      confirmation: "53212",
      expense_category: "Reimbursement",
      type_of_expense: "One-time payment",
      currency: "AED",
      amount: 5000,
      status: "Pending",
    },
  ],
  avail_bvi: [
    {
      corporate_name: "Mohammad Al Baik",
      brand_name: "MOBA",
      agreement: "Warrant to Purchase Tokens",
      agreement_date: "2024-09-23",
      commencement_date: "2024-09-30",
      tier: "Important",
      term: "Fixed",
      status: "Live",
      expiry_date: "2024-09-23",
      signed_agreement: null,
    },
    {
      corporate_name: "Oppa Gangnam Style",
      brand_name: "Oppa",
      agreement: "NDA",
      agreement_date: "2024-03-13",
      commencement_date: "2024-09-30",
      tier: "Mission Critical",
      term: "Auto-Renewal",
      status: "Live",
      expiry_date: "2024-09-23",
      signed_agreement: null,
    },
    {
      corporate_name: "Jai Ho",
      brand_name: null,
      agreement: "Listing Corporation",
      agreement_date: "2024-09-23",
      commencement_date: "2024-09-30",
      tier: "Important",
      term: "Fixed",
      status: "Expired",
      expiry_date: "2024-09-23",
      signed_agreement: null,
    },
    {
      corporate_name: "Habibi come to Dubai",
      brand_name: null,
      agreement: "Genesis Validator Delegation",
      agreement_date: "2024-09-23",
      commencement_date: "2024-09-30",
      tier: "Material",
      term: "By Notice",
      status: "Expired",
      expiry_date: "2024-07-11",
      signed_agreement: null,
    },
    {
      corporate_name: "Jollibee",
      brand_name: "Bida",
      agreement: "Advisory Agreement",
      agreement_date: "2024-09-23",
      commencement_date: "2024-09-30",
      tier: "Important",
      term: "Auto-Renewal",
      status: "Live",
      expiry_date: "2024-09-23",
      signed_agreement: null,
    },
  ],
  avail_cayman: [
    {
      corporate_name: "Mohammad Al Baik",
      brand_name: "MOBA",
      agreeeement: "SAFT",
      agreement_date: "2024-09-23",
      commencement_date: "2024-09-12",
      tier: "Important",
      term: "Fixed",
      status: "Live",
      expiry_date: "2024-09-23",
      signed_agreement: null,
    },
    {
      corporate_name: "Oppa Gangnam Style",
      brand_name: "Oppa",
      agreement: "Investor Rep Letter",
      agreement_date: "2024-03-13",
      commencement_date: "2024-09-23",
      tier: "Mission Critical",
      term: "Auto-Renewal",
      status: "Live",
      expiry_date: "2024-09-23",
      signed_agreement: null,
    },
    {
      corporate_name: "Jai Ho",
      brand_name: null,
      agreement: "NDA",
      agreement_date: "2024-09-23",
      commencement_date: "2024-09-30",
      tier: "Important",
      term: "Fixed",
      status: "Expired",
      expiry_date: "2024-09-23",
      signed_agreement: null,
    },
    {
      corporate_name: "Habibi come to Dubai",
      brand_name: null,
      agreement: "Termination",
      agreement_date: "2024-09-23",
      commencement_date: "2024-09-30",
      tier: "Material",
      term: "By Notice",
      status: "Expired",
      expiry_date: "2024-07-11",
      signed_agreement: null,
    },
    {
      corporate_name: "Jollibee",
      brand_name: "Bida",
      agreement: "Side Letter",
      agreement_date: "2024-09-23",
      commencement_date: "2024-09-30",
      tier: "Important",
      term: "Auto-Renewal",
      status: "Live",
      expiry_date: "2024-09-23",
      signed_agreement: null,
    },
  ],
  avail_difc: [
    {
      corporate_name: "Mohammad Al Baik",
      brand_name: "MOBA",
      agreement: "Partnership",
      agreement_date: "2024-09-23",
      commencement_date: "2024-09-23",
      tier: "Important",
      term: "Fixed",
      status: "Live",
      expiry_date: "2024-09-23",
      avail_poc: "xxxx",
      signed_agreement: "",
    },
    {
      corporate_name: "Oppa Gangnam Style",
      brand_name: "Oppa",
      agreement: "Commercial Collab",
      agreement_date: "2024-03-07",
      commencement_date: "2024-09-23",
      tier: "Mission Critical",
      term: "Auto-Renewal",
      status: "Live",
      expiry_date: "2024-09-23",
      avail_poc: "xxxx",
      signed_agreement: "",
    },
    {
      corporate_name: "Jai Ho",
      brand_name: "",
      agreement: "Supply of Services",
      agreement_date: "2024-09-23",
      commencement_date: "2024-09-23",
      tier: "Important",
      term: "Fixed",
      status: "Expired",
      expiry_date: "2024-09-12",
      avail_poc: "xxxx",
      signed_agreement: "",
    },
    {
      corporate_name: "Habibi come to Dubai",
      brand_name: "",
      agreement: "Marketing & Consulting",
      agreement_date: "2024-09-23",
      commencement_date: "2024-09-23",
      tier: "Material",
      term: "By Notice",
      status: "Expired",
      expiry_date: "2024-07-11",
      avail_poc: "xxxx",
      signed_agreement: "",
    },
    {
      corporate_name: "Jollibee",
      brand_name: "Bida",
      agreement: "Employment Agreement",
      agreement_date: "2024-09-23",
      commencement_date: "2 Sep 2024",
      tier: "Important",
      term: "Auto-Renewal",
      status: "Live",
      expiry_date: "8 Sep 2024",
      avail_poc: "xxxx",
      signed_agreement: "",
    },
  ],
  avail_wallet: [
    {
      vendor: "Mohammad Al Baik",
      invoice_date: "15-Aug-2024",
      invoice_no: "1 0 0 - 9 7",
      poc: "XXX",
      expense_category: "PR/Marketing Campaign",
      expense_type: "Recurring",
      currency: "Avail",
      amount: 0.131,
      wallet_address: "XXXXXXXXXXXXXX",
      confirmation: "XXXXXXXXXXXXX",
      status: "Paid",
      amountUSD: 24000,
      amountAvail: 175183,
      twap_price: 0.131,
    },
    {
      vendor: "Oppa Gangnam Style",
      invoice_date: "17-Aug-2024",
      invoice_no: "12479",
      poc: "XXXX",
      expense_category: "Contractor Payment",
      expense_type: "Recurring",
      currency: "Avail",
      amount: 0.1422,
      wallet_address: "XXXXXXXXXXXXXX",
      confirmation: "XXXXXXXXXXXXX",
      status: "Paid",
      amountUSD: 10000,
      amountAvail: 70323.5,
      twap_price: 0.1422,
    },
    {
      vendor: "Jai Ho",
      invoice_date: "06Sep2024",
      invoice_no: "1",
      poc: "XXX",
      expense_category: "Events",
      expense_type: "One-time payment",
      currency: "Avail",
      amount: 0.1209,
      wallet_address: "XXXXXXXXXXXXXX",
      confirmation: "XXXXXXXXXXXXX",
      status: "Paid",
      amountUSD: 33000,
      amountAvail: 272952.9,
      twap_price: 0.1209,
    },
    {
      vendor: "Habibi come to Dubai",
      invoice_date: "Sep 3 2024",
      invoice_no: "20134",
      poc: "XXXX",
      expense_category: "Service Provider",
      expense_type: "One-time payment",
      currency: "Avail",
      amount: 0.1181,
      wallet_address: "XXXXXXXXXXXXXX",
      confirmation: "XXXXXXXXXXXXX",
      status: "Paid",
      amountUSD: 5000,
      amountAvail: 42337,
      twap_price: 0.1181,
    },
    {
      vendor: "Jollibee",
      invoice_date: "Sep 12",
      invoice_no: "11112",
      poc: "XXXX",
      expense_category: "PR/Marketing Campaign",
      expense_type: "Recurring",
      currency: "Avail",
      amount: 0.1383,
      wallet_address: "XXXXXXXXXXXXXX",
      confirmation: "XXXXXXXXXXXXX",
      status: "Pending",
      amountUSD: 8000,
      amountAvail: 57845.3,
      twap_price: 0.1383,
    },
  ],
  ethereum_wallet: [
    {
      vendor: "Mohammad Al Baik",
      invoice_date: "2024-08-30",
      invoice_no: "5",
      poc: "XXXX",
      expense_category: "Contractor Payment",
      expense_type: "Recurring",
      currency: "USDT-Ethereum",
      amount: 800,
      wallet_address: "XXXXXXXXXXXXXXXXXXXXXXXXX",
      confirmation: "XXXXXXXXXXXXXXXXXXXXXXX",
      status: "Paid",
    },
    {
      vendor: "Oppa Gangnam Style",
      invoice_date: "2024-08-31",
      invoice_no: "5",
      poc: "XXXX",
      expense_category: "PR/Marketing Campaign",
      expense_type: "Recurring",
      currency: "USDT/USDC ERC20",
      amount: 2000,
      wallet_address: "XXXXXXXXXXXXXXXXXXXXXXXXX",
      confirmation: "XXXXXXXXXXXXXXXXXXXXXXX",
      status: "Paid",
    },
    {
      vendor: "Jai Ho",
      invoice_date: "2024-09-01",
      invoice_no: "-",
      poc: "XXXXX",
      expense_category: "Events",
      expense_type: "One-time payment",
      currency: "USDC",
      amount: 100,
      wallet_address: "XXXXXXXXXXXXXXXXXXXXXXXXX",
      confirmation: "XXXXXXXXXXXXXXXXXXXXXXX",
      status: "Paid",
    },
    {
      vendor: "Habibi come to Dubai",
      invoice_date: "2024-09-01",
      invoice_no: "20240905",
      poc: "XXXXX",
      expense_category: "Reimbursement",
      expense_type: "One-time payment",
      currency: "USDC/USDT",
      amount: 520,
      wallet_address: "XXXXXXXXXXXXXXXXXXXXXXXXX",
      confirmation: "XXXXXXXXXXXXXXXXXXXXXXX",
      status: "Paid",
    },
    {
      vendor: "Jollibee",
      invoice_date: "2024-09-01",
      invoice_no: "20240912",
      poc: "XXXXX",
      expense_category: "Salary",
      expense_type: "Recurring",
      currency: "USDT/USDC ERC20",
      amount: 10000,
      wallet_address: "XXXXXXXXXXXXXXXXXXXXXXXXX",
      confirmation: "XXXXXXXXXXXXXXXXXXXXXXX",
      status: "Pending",
    },
  ],
  credit_card_payments: [
    {
      payment_description: "Google Domains",
      payment_date: "2024-09-23",
      payment_source: "WIO Card",
      currency: "SGD",
      amount: 49.05,
      expense_category: "Subscription",
      expense_type: "Recurring",
      amountUSD: 37.57,
      status: "Paid",
    },
    {
      payment_description: "Grand Hyatt Denver",
      payment_date: "2024-09-23",
      payment_source: "WIO Card",
      currency: "USD",
      amount: 7735.83,
      expense_category: "Events",
      expense_type: "One-time payment",
      amountUSD: 7735.83,
      status: "Paid",
    },
    {
      payment_description: "Employee Medical Insurance",
      payment_date: "2024-05-24",
      payment_source: "WIO Card",
      currency: "AED",
      amount: 4940.41,
      expense_category: "Employee Welfare",
      expense_type: "One-time payment",
      amountUSD: 1345.09,
      status: "Paid",
    },
    {
      payment_description: "Laptop for Employee",
      payment_date: "2024-08-15",
      payment_source: "WIO Card",
      currency: "AED",
      amount: 8999,
      expense_category: "Employee Welfare",
      expense_type: "One-time payment",
      amountUSD: 2450,
      status: "Paid",
    },
    {
      payment_description: "Notion",
      payment_date: "2024-08-16",
      payment_source: "WIO Card",
      currency: "USD",
      amount: 666.46,
      expense_category: "Subscription",
      expense_type: "Recurring",
      amountUSD: 666.46,
      status: "Paid",
    },
  ],
};
