import React from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";

const RecentBank = ({staticsData}) => {
  return (
    <Col md="12">
      <Card>
        <CardHeader>
          <h4>Recent Bank Transfers</h4>
        </CardHeader>
        <CardBody>
          <Table className="data-transfer-table">
            <thead className="border-bottom">
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Amount (in USD)</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
                {staticsData?.bankPaymentsBudgets?.length > 0 ? (
                  staticsData?.bankPaymentsBudgets?.map((item) => (
                    <tr key={item.id}>
                      <td>{item.from}</td>
                      <td>{item.to}</td>
                      <td>{item.amount}</td>
                      <td>{item.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="">
                    <td colSpan="10" className="text-center no-found">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};
export default RecentBank;
