import React, { createContext, useContext, useState } from "react";

const BridgeTransferContext = createContext();

export const BridgeTransferProvider = ({ children }) => {
  const [editModal, setEditModal] = useState(false);
  const toggleEdit = () => setEditModal((prev) => !prev);
  const [importModal, setImportModal] = useState(false);
  const toggleImport = () => setImportModal((prev) => !prev);
  const [editBridgeTransferData, setEditBridgeTransferData] = useState({});
  const [searchTerm, setSearchTerm] = useState(null);

  return <BridgeTransferContext.Provider value={{ editModal, setEditModal, toggleEdit, importModal, setImportModal, toggleImport, setEditBridgeTransferData, editBridgeTransferData, searchTerm, setSearchTerm }}>{children}</BridgeTransferContext.Provider>;
};

export const useBridgerTransfer = () => {
  return useContext(BridgeTransferContext);
};
