import React from "react";
import { dynamicSvg, handleCopyCode } from "../../Utils";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";

const DecodedDetails = ({ decodedData, callHash }) => {
  return (
    <>
      <Card className="data-wallet-card mt-4">
        <CardHeader className="border-0 p-0">
          <Row>
            <Col xs="6">
              <div className="custom-data-label">
                <label>decoded call</label>
                <span>{decodedData?.section}</span>
              </div>
            </Col>
            <Col xs="6">
              <div>
                <span>{decodedData?.method}</span>
              </div>
            </Col>
            {/* <Col xs="4">
              <div>
                <span>See [`Pallet::transfer_keep_alive`].</span>
              </div>
            </Col> */}
          </Row>
        </CardHeader>
        <CardBody>
          <div className="custom-wallet-body">
            <div className="custom-ring">
              <img className="img-fluid" src={dynamicSvg("ring-wallet.svg")} alt="icon" />
            </div>
            <span className="custom-data-label">dest: MultiAddress</span>
            <div className="d-flex justify-content-between align-items-center">
              <span>{decodedData?.args?.Id}</span>
            </div>
          </div>
          <div className="custom-wallet-body mt-3">
            <span className="custom-data-label">value: Compact'</span>
            <div className="d-flex justify-content-between align-items-center">
              <span>{decodedData?.args?.[1]}</span>
            </div>
          </div>
        </CardBody>
        <CardFooter className="custom-wallet-footer border-0">
          <div className="custom-data-call">
            <span className="custom-data-title d-block">Encoded call hash</span>
            <p>{callHash}</p>
          </div>
          <div onClick={() => handleCopyCode(callHash, "Hash")} style={{ cursor: "pointer" }}>
            <i className="fa-regular fa-copy" />
          </div>
        </CardFooter>
      </Card>
      <div className="custom-roll-card">
        <div className="custom-card__header">Encoding Details</div>
        <div className="custom-card__body">
          <p>
            <span>Call Index:</span> {decodedData?.callIndex}
          </p>
          <p>
            <span>Dest:</span> {decodedData?.args[0]?.Id}
          </p>
          <p>
            <span>Value:</span> 13000064a73b6e00d
          </p>
          <div className="custom-data-link mt-2">
            <Link target="_blank" to={`https://explorer.avail.so/?rpc=wss://turing-rpc.avail.so/ws#/extrinsics/decode/${decodedData?.raw?.hex}`}>
              View Link
            </Link>
            <i className="fa-solid fa-up-right-from-square ms-1"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default DecodedDetails;
