import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createOrUpdateBudgetApi } from "../../../Api/ApiList";
import { usePerformMutation } from "../../../Api/PerformMutation";
import useNotifications from "../../../Api/useNotifications";
import { useBudget } from "../../../Context/BudgetContext";

const AgreementModal = ({ isEditMode }) => {
  const { refetch, editModal, editBudgetData, toggleEdit } = useBudget();

  const { mutate } = usePerformMutation(
    () => {
      refetch();
      reset();
      toggleEdit();
      setTimeout(() => {
        refetchGetData();
      }, 500);
    },
    () => {
      setLoading(false);
    }
  );

  const [loading, setLoading] = useState(false);
  const [datePickerVisibility, setDatePickerVisibility] = useState({
    agreement_date: false,
    commencement_date: false,
    expiry_date: false,
  });
  const datePickerRefs = useRef({});
  const { refetchGetData } = useNotifications();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "avail_bvi",
      id: null,
      corporate_name: null,
      brand_name: null,
      agreement: null,
      agreement_date: null,
      commencement_date: null,
      tier: null,
      term: null,
      status: null,
      expiry_date: null,
      signed_agreement: null,
    },
  });

  const handleAgreement = async (data) => {
    setLoading(true);
    await mutate({ url: createOrUpdateBudgetApi, data });
    setLoading(false);
  };

  const handleDateChange = (name, date) => {
    setValue(name, date);
    setDatePickerVisibility((prev) => ({ ...prev, [name]: false }));
  };

  const toggleDatePicker = (fieldName) => {
    setDatePickerVisibility((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
  };

  useEffect(() => {
    if (isEditMode && editBudgetData) {
      setValue("id", editBudgetData.id);
      setValue("corporate_name", editBudgetData.corporate_name);
      setValue("brand_name", editBudgetData.brand_name);
      setValue("agreement", editBudgetData.agreement);
      setValue("agreement_date", editBudgetData.agreement_date ? new Date(editBudgetData.agreement_date) : null);
      setValue("commencement_date", editBudgetData.commencement_date ? new Date(editBudgetData.commencement_date) : null);
      setValue("tier", editBudgetData.tier);
      setValue("term", editBudgetData.term);
      setValue("status", editBudgetData.status);
      setValue("expiry_date", editBudgetData.expiry_date ? new Date(editBudgetData.expiry_date) : null);
      setValue("signed_agreement", editBudgetData.signed_agreement);
    } else {
      reset();
    }
  }, [isEditMode, editBudgetData, setValue, reset]);

  return (
    <Modal size="lg" centered isOpen={editModal} toggle={toggleEdit} className="transaction-modal budget-modal">
      <ModalHeader toggle={toggleEdit}>{isEditMode ? "Edit Avail BVI Payment" : "Create Avail BVI Payment"}</ModalHeader>
      <Form onSubmit={handleSubmit(handleAgreement)} noValidate autoComplete="off">
        <ModalBody>
          <Row className="g-3">
            {/* Corporate Name */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Corporate Name:</label>
                <input {...register("corporate_name", { required: "Corporate Name is required" })} className={`form-control ${errors.corporate_name ? "is-invalid" : ""}`} type="text" placeholder="Enter corporate name" />
                {errors.corporate_name && <div className="invalid-feedback">{errors.corporate_name.message}</div>}
              </FormGroup>
            </Col>

            {/* Brand Name */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Brand Name:</label>
                <input {...register("brand_name")} className={`form-control ${errors.brand_name ? "is-invalid" : ""}`} type="text" placeholder="Enter brand name" />
                {/* {errors.brand_name && <div className="invalid-feedback">{errors.brand_name.message}</div>} */}
              </FormGroup>
            </Col>

            {/* Agreement */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Agreement:</label>
                <input {...register("agreement")} className={`form-control ${errors.agreement ? "is-invalid" : ""}`} type="text" placeholder="Enter agreement type" />
                {/* {errors.agreement && <div className="invalid-feedback">{errors.agreement.message}</div>} */}
              </FormGroup>
            </Col>

            {/* Agreement Date */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Agreement Date:</label>
                <div className="calander-icon" ref={(el) => (datePickerRefs.current.agreement_date = el)}>
                  <DatePicker showYearDropdown selected={watch("agreement_date")} className={`form-control ${errors.agreement_date ? "is-invalid" : ""}`} placeholderText="Select agreement date" onChange={(date) => handleDateChange("agreement_date", date)} open={datePickerVisibility.agreement_date} onClickOutside={() => setDatePickerVisibility((prev) => ({ ...prev, agreement_date: false }))} />
                  <i className="fa-solid fa-calendar-days" onClick={() => toggleDatePicker("agreement_date")} />
                </div>
                {/* {errors.agreement_date && <div className="invalid-feedback">{errors.agreement_date.message}</div>} */}
              </FormGroup>
            </Col>

            {/* Commencement Date */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Commencement Date:</label>
                <div className="calander-icon" ref={(el) => (datePickerRefs.current.commencement_date = el)}>
                  <DatePicker showYearDropdown selected={watch("commencement_date")} className={`form-control ${errors.commencement_date ? "is-invalid" : ""}`} placeholderText="Select commencement date" onChange={(date) => handleDateChange("commencement_date", date)} open={datePickerVisibility.commencement_date} onClickOutside={() => setDatePickerVisibility((prev) => ({ ...prev, commencement_date: false }))} />
                  <i className="fa-solid fa-calendar-days" onClick={() => toggleDatePicker("commencement_date")} />
                </div>
                {errors.commencement_date && <div className="invalid-feedback">{errors.commencement_date.message}</div>}
              </FormGroup>
            </Col>

            {/* Tier */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Tier:</label>
                <input {...register("tier")} className={`form-control ${errors.tier ? "is-invalid" : ""}`} type="text" placeholder="Enter tier" />
                {/* {errors.tier && <div className="invalid-feedback">{errors.tier.message}</div>} */}
              </FormGroup>
            </Col>

            {/* Term */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Term:</label>
                <input {...register("term")} className={`form-control ${errors.term ? "is-invalid" : ""}`} type="text" placeholder="Enter term" />
                {/* {errors.term && <div className="invalid-feedback">{errors.term.message}</div>} */}
              </FormGroup>
            </Col>

            {/* Expiry Date */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Expiry Date:</label>
                <div className="calander-icon" ref={(el) => (datePickerRefs.current.expiry_date = el)}>
                  <DatePicker showYearDropdown selected={watch("expiry_date")} className={`form-control ${errors.expiry_date ? "is-invalid" : ""}`} placeholderText="Select expiry date" onChange={(date) => handleDateChange("expiry_date", date)} open={datePickerVisibility.expiry_date} onClickOutside={() => setDatePickerVisibility((prev) => ({ ...prev, expiry_date: false }))} />
                  <i className="fa-solid fa-calendar-days" onClick={() => toggleDatePicker("expiry_date")} />
                </div>
                {errors.expiry_date && <div className="invalid-feedback">{errors.expiry_date.message}</div>}
              </FormGroup>
            </Col>

            {/* Status  */}
            <Col sm="6">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">Status:</label>
                <select {...register("status", { required: "Status is required" })} className={`form-control search-users ${errors.status ? "is-invalid" : ""}`}>
                  <option value="">Select status</option>
                  <option value="Live">Live</option>
                  <option value="Expired">Expired</option>
                </select>
                {errors.status && <div className="invalid-feedback">{errors.status.message}</div>}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggleEdit}>
            Cancel
          </Button>
          <Button type="submit" color="" disabled={loading}>
            {loading ? "Saving..." : isEditMode ? "Update" : "Create"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AgreementModal;
