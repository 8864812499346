import InvoiceContainer from "../Components/Billing/Invoices";
import PaymentHistory from "../Components/Billing/PaymentHistory";
import BudgetContainer from "../Components/Budget/";
import ExpensesContainer from "../Components/Expenses";
import LiquidityContainer from "../Components/Liquidity";
import Notifications from "../Components/Notifications";
import Peoples from "../Components/Peoples";
import UsersContainer from "../Components/Users/AllUsers";
import RolesContainer from "../Components/Users/Roles";
import RoleForm from "../Components/Users/Roles/RoleForm";
import AvailWalletContainer from "../Components/Wallet";
import Account from "../Pages/Account/Account";
import ForgotPassword from "../Pages/Auth/ForgotPassword/ForgotPassword";
import LogIn from "../Pages/Auth/Login/LogIn";
import UpdatePassword from "../Pages/Auth/UpdatePassword/UpdatePassword";
import AvailInfo from "../Pages/AvailInfo";
import BridgeTransfer from "../Pages/BridgeTransfer";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Multisig from "../Pages/Multisig";
import Transaction from "../Pages/Transaction/Transaction";

export const protRoutes = [
  { path: "/transaction", element: <Transaction /> },
  { path: "/account", element: <Account /> },
  { path: "/statistics", element: <Dashboard /> },
  { path: "/notifications", element: <Notifications /> },
  { path: "/budget/bank_payments", element: <BudgetContainer /> },
  { path: "/budget/credit_card_payments", element: <BudgetContainer /> },
  { path: "/budget/avail_wallet", element: <BudgetContainer /> },
  { path: "/budget/avail_bvi", element: <BudgetContainer /> },
  { path: "/budget/avail_difc", element: <BudgetContainer /> },
  { path: "/budget/avail_cayman", element: <BudgetContainer /> },
  { path: "/budget/ethereum_wallet", element: <BudgetContainer /> },
  { path: "/billing/invoices", element: <InvoiceContainer /> },
  { path: "/billing/payment_history", element: <PaymentHistory /> },
  { path: "/bridge_transfer", element: <BridgeTransfer /> },
  { path: "/peoples", element: <Peoples /> },
  { path: "/expenses", element: <ExpensesContainer /> },
  { path: "/users/all_user", element: <UsersContainer /> },
  { path: "/users/role", element: <RolesContainer /> },
  { path: "/users/role_form", element: <RoleForm /> },
  { path: "/decoder", element: <AvailWalletContainer /> },
  { path: "/liquidity", element: <LiquidityContainer /> },
  { path: "/avail_info", element: <AvailInfo /> },
  { path: "/multisig", element: <Multisig /> },
];

export const authRoutes = [
  { path: `/login`, element: <LogIn /> },
  { path: `/forgot_password`, element: <ForgotPassword /> },
  { path: `/update_password/:token`, element: <UpdatePassword /> },
];
