import React from "react";
import { Col, Row } from "reactstrap";
import LoginContainer from "../../../Components/Auth/Login";

const LogIn = () => {
  return (
    <div className="login-section">
      <Row className="login-content align-items-center g-0">
        <Col xl="5" lg="6" md="7" sm="8" xs="10" className="mx-auto">
          <div className="login-main">
            <LoginContainer />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LogIn;
