import { Fragment, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

const PermissionTable = ({ values, errors, touched, setFieldValue }) => {
  const [checked, setChecked] = useState(false);
  const data = ["transaction.index", "transaction.create", "role.index", "user.index", "user.create", "user.edit", "user.destroy", "account.index", "account.create", "account.edit", "account.destroy", "budget.index", "budget.create", "budget.edit", "budget.destroy", "bridge_transfers.index", "agreement.create", "agreement.destroy", "deel_people.index", "deel_payment_history.index", "deel_invoice.index", "deel_expense.index", "liquidity.index", "notification.index", "statistics.index", "wallet.index"];

  const permissions = data.map((name) => ({ name }));

  const formatPermissions = (permissions) => {
    const grouped = permissions.reduce((acc, { name }) => {
      const [moduleName] = name.split(".");

      if (!acc[moduleName]) {
        acc[moduleName] = {
          name: moduleName,
          module_permissions: [],
        };
      }

      acc[moduleName].module_permissions.push({ name });
      return acc;
    }, {});

    return Object.values(grouped);
  };

  const formattedPermissions = formatPermissions(permissions);

  const handleAllCheckUncheck = (e, permissionArray) => {
    setChecked(!checked);
    if (e.target.checked) {
      const newPermissions = permissionArray.filter((permission) => !values("permissions").includes(permission));
      setFieldValue("permissions", [...values("permissions"), ...newPermissions]);
    } else {
      const remainingPermissions = values("permissions").filter((perm) => !permissionArray.includes(perm));
      setFieldValue("permissions", remainingPermissions);
    }
  };

  const handleCheckUncheck = (e, permission, obj) => {
    setChecked(!checked);
    if (e.target.checked) {
      const indexPermission = obj.module_permissions.find((perm) => perm.name === `${obj.name}.index`);
      let permissionsToAdd = [permission];
      if (indexPermission && !values("permissions").includes(indexPermission.name)) {
        permissionsToAdd.push(indexPermission.name);
      }
      setFieldValue("permissions", [...values("permissions"), ...permissionsToAdd]);
    } else {
      const updatedPermissions = values("permissions").filter((perm) => perm !== permission);
      setFieldValue("permissions", updatedPermissions);
    }
  };

  const handleCheckOnIndex = (permissionTitles) => {
    const permissionNames = permissionTitles.module_permissions.map((perm) => perm.name);
    const remainingPermissions = values("permissions").filter((perm) => !permissionNames.includes(perm));
    setFieldValue("permissions", remainingPermissions);
  };

  return (
    <FormGroup className="form-group">
      <Label className="mb-3 form-label">Permissions *</Label>
      {errors.permissions && <div className="text-danger">{errors.permissions.message}</div>}

      <div className="permissions">
        <Row className="roles-form">
          {formattedPermissions.map((permissionTitles, i) => (
            <Fragment key={i}>
              <Col sm={12}>
                <ul className="mb-0 custom-scrollbar">
                  <li>
                    <Label className="mb-0">{permissionTitles.name.charAt(0).toUpperCase() + permissionTitles?.name?.slice(1)}</Label>
                  </li>
                  <li>
                    <FormGroup check>
                      <Input
                        className="checkbox_animated"
                        id={permissionTitles.name}
                        name="permissions"
                        type="checkbox"
                        checked={permissionTitles.module_permissions.every((permission) => values("permissions").includes(permission.name))}
                        onChange={(e) =>
                          handleAllCheckUncheck(
                            e,
                            permissionTitles.module_permissions.map((permission) => permission.name)
                          )
                        }
                      />

                      <Label className="form-check-label m-0" htmlFor={permissionTitles.name}>
                        All
                      </Label>
                    </FormGroup>
                  </li>
                  {permissionTitles.module_permissions.map((permissionDetails) => (
                    <li>
                      <FormGroup check key={permissionDetails.name}>
                        <Input className="checkbox_animated" id={"role" + permissionDetails.name} type="checkbox" name="permissions" checked={values("permissions").includes(permissionDetails.name)} onChange={(e) => (permissionDetails.name.endsWith("index") && values("permissions").includes(permissionDetails.name) ? handleCheckOnIndex(permissionTitles) : handleCheckUncheck(e, permissionDetails.name, permissionTitles))} />
                        <Label className="form-check-label m-0" htmlFor={"role" + permissionDetails.name}>
                          {permissionDetails.name.split(".")[1]}
                        </Label>
                      </FormGroup>
                    </li>
                  ))}
                </ul>
              </Col>
            </Fragment>
          ))}
        </Row>
      </div>
    </FormGroup>
  );
};

export default PermissionTable;
