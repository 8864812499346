import React from "react";
import { useBudget } from "../../../Context/BudgetContext";
import usePermissionCheck from "../../../Hooks/usePermissionCheck";
import { handleCapitalizeMultiple } from "../../../Utils";
import CommonTable from "../../Common/CommonTable";

const EthereumPayments = ({ data, isLoadingGetData, setEditMode, handleDelete }) => {
  const { handleSort, sortOrder } = useBudget();
  const [edit, destroy] = usePermissionCheck(["edit", "destroy"], "budget");

  const columns = [
    { label: "Vendor", key: "vendor", isSortable: true },
    { label: "Invoice No", key: "invoice_no", isSortable: true },
    { label: "POC", key: "poc" },
    { label: "Expense Category", key: "expense_category" },
    { label: "Type of Expense", key: "expense_type" },
    { label: "Currency", key: "currency" },
    { label: "Amount", key: "amount", type: "amount", isSortable: true },
    { label: "Amount (USD)", key: "amountUSD", type: "amount" },
    { label: "Wallet Address", key: "wallet_address", type: "address", truncate: true },
    { label: "Confirmation (TXN)", key: "confirmation", type: "address", truncate: true },
    { label: "Status", key: "status", isSortable: true, render: (item) => <span className={`badge ${item?.status === "Paid" || item?.status === "completed" ? "badge-light-success" : item?.status === "Expired" || item?.status === "Cancelled" ? "badge-light-danger" : "badge-light-warning  "}`}> {handleCapitalizeMultiple(item?.status)} </span> },
  ];

  return <CommonTable serialNo handleSort={handleSort} sortOrder={sortOrder} tableClass={"budget-table"} columns={columns} data={data?.data || []} isLoading={isLoadingGetData} onEdit={edit ? setEditMode : false} onDelete={destroy ? handleDelete : false} />
};

export default EthereumPayments;
