import { web3FromSource } from "@polkadot/extension-dapp";
import { hexToU8a } from "@polkadot/util";
import { blake2AsHex } from "@polkadot/util-crypto";
import { BN } from "bn.js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useMultisig } from "../../../Context/MultisigContext";
import { useWallet } from "../../../Context/WalletContext";
import { handleCopyCode } from "../../../Utils";
import { generateEncodedCallData, getInjectorMetadata } from "../../Common/AvailCommon";

const SendAvailModal = ({ isOpen, toggle, senderDetails }) => {
  const [loading, setLoading] = useState(false);
  const { setTransactionUpdates } = useMultisig();
  const [encodedCallData, setEncodedCallData] = useState(""); // New state for encoded call data
  const { api } = useWallet();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      from_address: "", // Leave it empty initially
      to_address: "",
      amount: "",
    },
  });

  const closeModal = () => {
    toggle("callData");
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, ""); // Remove any commas
    const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas as thousands separators
    setValue("amount", formattedValue); // Update the form state
  };

  const handleCancel = () => {
    closeModal();
    setEncodedCallData("");
    reset({
      from_address: senderDetails?.ms_address || "",
      to_address: "",
      amount: "",
    });
  };
  const onSubmit = async (data) => {
    setLoading(true);

    // Convert amount to the required large unit (1e18)
    const amountInWei = new BN(String(data.amount).replace(/,/g, "")); // Remove commas, convert to BN
    const amountInLargeUnit = amountInWei.mul(new BN("1000000000000000000")); // Multiply by 1e18

    // Call generateEncodedCallData with the necessary parameters
    const generatedData = await generateEncodedCallData(api, "balances", "transferKeepAlive", [
      { Id: data.to_address }, // or directly use data.from_address
      amountInLargeUnit, // Use the amount in large unit
    ]);

    // Set the generated encoded call data in the state
    setEncodedCallData(generatedData);
    handleCopyCode(generatedData, "Call data");
    setLoading(false);
  };

  const signTransaction = async (data) => {
    if (!api) {
      toast.error("Incomplete setup: Ensure API is connected, wallet is selected, and transaction data is decoded.");
      return;
    }
    try {
      const injector = await web3FromSource("subwallet-js");
      if (!api) await injector.metadata.provide(getInjectorMetadata(api));
      const fromAddress = data?.to_address;
      const callHash = await blake2AsHex(hexToU8a(encodedCallData));

      const otherSignatories = senderDetails?.members
        ? JSON.parse(senderDetails.members)
            .filter((member) => member.address !== fromAddress) // Exclude the sender's address
            .map((member) => member.address)
            .sort() // Must be sorted
        : [];

      const transfer = api.tx.multisig.approveAsMulti(Number(senderDetails?.threshold), otherSignatories, null, callHash, {
        refTime: 196085000,
        proofSize: 3593,
      });

      await transfer.signAndSend(fromAddress, { signer: injector.signer }, ({ status, events }) => {
        if (status.isInBlock) {
          toast.success(`Transaction successful! Hash: ${status.asInBlock.toHex()}`);
        } else if (status.isFinalized) {
          toast.success("Transaction finalized.");
        } else {
          toast.success(`Transaction status: ${status.type}`);
        }

        events.forEach(({ phase, event: { data, method, section } }) => {
          setTransactionUpdates(`First approval done for ${data.hash}`);
          console.log(`${phase.toString()} : ${section}.${method} ${data.toString()}`);
        });
      });

      reset({
        from_address: senderDetails?.ms_address || "",
        to_address: "",
        amount: "",
      });
      closeModal();
      setEncodedCallData("");
      setTransactionUpdates(`First approval done for ${data.hash}`);
    } catch (err) {
      setTransactionUpdates(`Error occurred for ${data.hash}`);
      toast.error(`Transaction failed: ${err.message}`);
      console.error(err);
    }
  };

  // Update the 'from_address' when selectedAccount changes
  useEffect(() => {
    if (senderDetails) {
      setValue("from_address", senderDetails?.ms_address); // Set the value programmatically
    }
  }, [senderDetails, setValue]);

  return (
    <Modal size="lg" centered isOpen={isOpen} toggle={closeModal} className="transaction-modal budget-modal">
      <ModalHeader toggle={closeModal}>{"Generate encoded call data"}</ModalHeader>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(encodedCallData ? signTransaction : onSubmit)}>
        <ModalBody>
          <Row className="g-3">
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">From Address:</label>
                <input {...register("from_address", { required: "Address is required" })} className={`form-control ${errors.from_address ? "is-invalid" : ""}`} type="text" placeholder="Enter avail address" readOnly />
                <p className="helper-text"> The transferred balance will be subtracted (along with fees) from the sender account.</p>
                {errors.from_address && <div className="invalid-feedback">{errors.from_address.message}</div>}
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">* To Address:</label>
                <input {...register("to_address", { required: "Address is required" })} className={`form-control ${errors.to_address ? "is-invalid" : ""}`} type="text" placeholder="Enter to address" />
                <p className="helper-text">The beneficiary will have access to the transferred fees when the transaction is included in a block.</p>
                {errors.to_address && <div className="invalid-feedback">{errors.to_address.message}</div>}
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="form-group">
                <label className="mb-2 form-label">* Amount:</label>
                <input
                  {...register("amount", {
                    required: "Amount is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Amount must be a number",
                    },
                  })}
                  className={`form-control ${errors.amount ? "is-invalid" : ""}`}
                  type="text"
                  placeholder="Enter amount"
                  onChange={handleAmountChange} // Call the handler on change
                />
                <p className="helper-text">Amount is the total number of AVAIL </p>
                {errors.amount && <div className="invalid-feedback">{errors.amount.message}</div>}
              </FormGroup>
            </Col>

            {/* Display the generated encoded call data */}
            {encodedCallData && (
              <Col sm="12">
                <FormGroup className="form-group">
                  <label className="mb-2 form-label">Encoded Call Data:</label>
                  <textarea className="form-control" value={encodedCallData} rows="5" readOnly />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={loading}>
            {encodedCallData ? "Sign And Send" : loading ? "Submitting..." : "Submit"}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SendAvailModal;
